// @/utils/assetLoader.js

/**
 * Dynamically imports an SVG mask file for puzzle pieces.
 *
 * @param {string} path - The path to the SVG file relative to the masks directory.
 * @returns {Promise<any>} A promise that resolves to the imported SVG module.
 */
export function requireMask(path) {
  return import(`@/assets/masks/${path}`).then((module) => module.default);
}

/**
 * Synchronously requires an SVG mask file for puzzle pieces.
 * Use this function if you need to load the SVG synchronously, but be cautious
 * as it may impact performance for larger applications.
 *
 * @param {string} path - The path to the SVG file relative to the masks directory.
 * @returns {string} The URL of the imported SVG file.
 */
export function requireMaskSync(path) {
  return require(`@/assets/masks/${path}`);
}

/**
 * Dynamically imports an image file.
 *
 * @param {string} path - The path to the image file relative to the assets directory.
 * @returns {Promise<any>} A promise that resolves to the imported image module.
 */
export function requireImage(path) {
  return import(`@/assets/${path}`).then((module) => module.default);
}

/**
 * Synchronously requires an image file.
 * Use this function if you need to load the image synchronously, but be cautious
 * as it may impact performance for larger applications.
 *
 * @param {string} path - The path to the image file relative to the assets directory.
 * @returns {string} The URL of the imported image file.
 */
export function requireImageSync(path) {
  return require(`@/assets/${path}`);
}
