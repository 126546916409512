<template>
  <div class="play-container">
    <h1 class="fpo-message" v-if="puzzlePieceStore && puzzlePieceStore.message">
      {{ puzzlePieceStore.message ?? '' }}
    </h1>
    <div class="debug-controls">
      <select v-model="selectedImage">
        <option v-for="image in puzzleImages" :key="image" :value="image">
          {{ image }}
        </option>
      </select>
      <select v-model="level" id="" class="level">
        <option v-for="n in 10" :key="n" :value="n">
          Puzzle length: {{ n + 1 }}
        </option>
      </select>

      <button @click="toggleDoorsOpen">
        {{ doorsOpen ? 'Close doors' : 'Open doors' }}
      </button>

      <button @click="toggleShowPopup">Hide puzzle board</button>
      <button @click="startGame">Start game</button>
    </div>

    <div class="shared-container" ref="wrapperRef" @click="checkSquare">
      <div class="train-track"></div>
      <div
        class="animation-container"
        ref="trainElement"
        :class="{ invisible: isTrainHidden }"
        @mousedown="startDrag"
        :style="{ transform: `translateX(${dragOffset}px)` }"
      >
        <TrainCar
          v-for="(car, index) in trainCars"
          :key="'car-' + index"
          :car="car"
          :index="index"
          :doorsOpen="doorsOpen"
          :imageURL="imageURL"
          :puzzleVersion="puzzleVersion"
          :distractorVersion="distractorVersion"
          :numberOfPieces="numberOfPieces"
          :wheelStyle="wheelStyle"
          :getCarStyle="getCarStyle"
          :getCarClass="getCarClass"
        />
      </div>
      <div id="tree-left" ref="treeLeftRef"></div>
      <div id="tree-right" ref="treeRightRef"></div>
    </div>

    <div class="puzzle-board">
      <div class="pieces"></div>
      <img
        :src="require('@/assets/2732/puzzleBoard-withLegs.webp')"
        alt="puzzle board"
      />
    </div>

    <button @click="checkSquare" class="lever">
      <span class="handle" :class="{ animate: isChecking }"></span>
    </button>
  </div>
</template>

<script>
  import {
    ref,
    watch,
    onMounted,
    onBeforeUnmount,
    nextTick,
    computed,
  } from 'vue';
  import { usePuzzlePieceAnimator } from '@/composables/usePuzzlePieceAnimator';
  import { useGameAudio } from '@/composables/useGameStore';
  import { usePuzzlePieceStore } from './stores/puzzlePieceStore';
  import { useCheckSquare } from '@/composables/useCheckSquare';
  import { usePuzzleImages } from './composables/usePuzzleImages';
  import puzzleConfigs from '@/config/puzzleConfigs';
  import TrainCar from '@/components/TrainCar.vue';

  export default {
    components: {
      TrainCar,
    },
    setup() {
      const isContentLoaded = ref(false);
      const isDragging = ref(false);
      const startX = ref(0);
      const dragOffset = ref(0);
      const puzzlePieces = ref([]);
      const puzzleElements = ref([]);
      const doorsOpen = ref(false);

      const puzzlePieceStore = usePuzzlePieceStore();
      const gameAudio = useGameAudio();
      const themeAudio = require('@/assets/audio/theme.mp3');
      const gamePlayAudio = require('@/assets/audio/gameplay.mp3');
      const effectPressCorrectAudio = require('@/assets/audio/effect-press-correct.mp3');

      gameAudio.preloadAudioFiles([
        themeAudio,
        gamePlayAudio,
        effectPressCorrectAudio,
      ]);

      const startDrag = (event) => {
        isDragging.value = true;
        startX.value = event.clientX - dragOffset.value;
        document.addEventListener('mousemove', drag);
        document.addEventListener('mouseup', stopDrag);
      };

      const drag = (event) => {
        if (!isDragging.value) return;
        dragOffset.value = event.clientX - startX.value;
      };

      const stopDrag = () => {
        isDragging.value = false;
        document.removeEventListener('mousemove', drag);
        document.removeEventListener('mouseup', stopDrag);
      };

      onMounted(() => {
        wrapperRef.value = document.querySelector('.wrapper');
        treeLeftRef.value = document.querySelector('#tree-left');
        treeRightRef.value = document.querySelector('#tree-right');

        isContentLoaded.value = true;

        calculateConnectorDimensions();
      });

      onBeforeUnmount(() => {
        document.removeEventListener('mousemove', drag);
        document.removeEventListener('mouseup', stopDrag);
      });

      watch(isContentLoaded, (newValue) => {
        if (newValue) {
          nextTick(() => {
            // Force a re-render or update layout if needed
            calculateConnectorDimensions();
          });
        }
      });

      onBeforeUnmount(() => {
        window.removeEventListener('resize', resizeHandler);
      });

      const FAKE_LEVEL_START = 2;
      const position = ref(-50);
      const message = ref('');
      const isPaused = ref(false);
      const isGameOver = ref(false);
      const carLength = 40;
      const carSpacing = 0.2;
      const puzzleVersion = ref('v1');
      const distractorVersion = ref('v2');
      let animatePuzzlePieces;

      const trainSpeed = ref(0.3);
      const wheelRotation = ref(0);

      const numberOfPieces = ref(2);
      const leadBeforeFirstPiece = ref(3);
      const trainElement = ref(null);
      let animationFrameId = null;
      const level = ref(parseInt(localStorage.getItem('level')) || 1);
      const trainLength = ref(0);
      const target = ref(0);
      const isTrainHidden = ref(true);
      const spacingOffset = ref(0);
      const imageURL = ref('');

      const wrapperRef = ref(null);
      const treeLeftRef = ref(null);
      const treeRightRef = ref(null);

      const trainCars = ref([]);

      const { isChecking, checkSquare, checkActiveSquare, getActiveCarIndex } =
        useCheckSquare(
          trainCars,
          leadBeforeFirstPiece,
          spacingOffset,
          wrapperRef,
          treeLeftRef,
          treeRightRef
        );

      const puzzleImages = usePuzzleImages();

      const selectedImage = ref(null);

      const updateImageURL = () => {
        console.log('Updating image URL', selectedImage.value);
        if (selectedImage.value !== null) {
          console.log('Image URL not null');
          imageURL.value = require(
            `@/assets/puzzles/1200/${selectedImage.value}`
          );
        }
      };

      watch(selectedImage, (newValue) => {
        console.log('Selected image changed:', newValue);
        if (newValue !== null) {
          updateImageURL();
        }
      });

      const trainCarImages = [
        'traincar-1-base.webp',
        'traincar-2-base.webp',
        'traincar-3-base.webp',
        'traincar-4-base.webp',
        'traincar-5-base.webp',
        'traincar-6-base.webp',
      ];

      const getPuzzleVersions = (numberOfPieces) => {
        const puzzleConfig = puzzleConfigs[numberOfPieces];

        if (!puzzleConfig) {
          console.error(`No configuration found for ${numberOfPieces} pieces`);
          return [];
        }

        return Object.keys(puzzleConfig).filter((key) => key.startsWith('v'));
      };

      const puzzleVersions = computed(() => {
        return getPuzzleVersions(numberOfPieces.value);
      });

      // puzzleVersion.value =
      //   puzzleVersions.value[
      //     Math.floor(Math.random() * puzzleVersions.value.length)
      //   ];

      const generateDistractorVersion = () => {
        const availableVersions = puzzleVersions.value.filter(
          (v) => v !== puzzleVersion.value
        );
        return availableVersions[
          Math.floor(Math.random() * availableVersions.length)
        ];
      };

      distractorVersion.value = generateDistractorVersion();

      console.log(
        'Puzzle Version:',
        puzzleVersion.value,
        'Distractor Version:',
        distractorVersion.value
      );

      const levelData = Array.from({ length: 10 }, (_, i) => ({
        trainSpeed: 0.3,
        leadBeforeFirstPiece: 2,
        numberOfPieces: FAKE_LEVEL_START + i,
      }));
      const toggleDoorsOpen = () => {
        doorsOpen.value = !doorsOpen.value;
      };

      const toggleShowPopup = () => {
        console.log('hide .puzzle-board');
        document.querySelector('.puzzle-board').classList.add('hide');
        document
          .querySelectorAll('.puzzle-piece-end-of-trial-wrapper')
          .forEach((element) => {
            element.classList.add('hide');
          });
      };

      const connectorWidth = ref(0);
      const connectorLeft = ref(0);

      const currentLevelData = ref(levelData[level.value - 1]);

      const calculateConnectorDimensions = async () => {
        await nextTick();

        const parentElement = document.querySelector('.wrapper');
        if (!parentElement) {
          console.error('Parent element not found');
          return;
        }

        const trainCarElements = document.querySelectorAll('.train-car');
        if (trainCarElements.length < 2) {
          console.log('Not enough train cars for calculation');
          return;
        }

        const firstCarElement = trainCarElements[0];
        const secondCarElement = trainCarElements[1];

        if (firstCarElement && secondCarElement) {
          const firstCarRect = firstCarElement.getBoundingClientRect();
          const secondCarRect = secondCarElement.getBoundingClientRect();

          const spaceBetweenCars =
            secondCarRect.x - (firstCarRect.x + firstCarRect.width);

          connectorWidth.value = spaceBetweenCars + 15;
        } else {
          console.log(
            'Elements not found for calculating connector dimensions'
          );
          return;
        }

        trainCars.value = trainCars.value.map((car, index, array) => {
          const currentCarWidth = 185;
          const currentConnectorWidth = connectorWidth.value + 100;

          const updatedCar = {
            ...car,
            connectorWidth: currentConnectorWidth,
            connectorLeft: -currentCarWidth,
            carWidth: currentCarWidth,
          };

          return updatedCar;
        });
      };

      const getCarStyle = (car, index) => {
        return {
          left: car.position + '%',
          zIndex: 49 - index,
        };
      };

      const getCarClass = (car) => {
        return {
          'train-engine': car.isEngine,
          tanker: car.trainCarImage === 'traincar-3-base.webp' && !car.isEngine,
        };
      };

      const distractorShuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
      };

      const generateDistractorPieceIndexes = (numberOfPieces) => {
        const originalOrder = Array.from(
          { length: numberOfPieces },
          (_, i) => i
        );
        let shuffledOrder;

        do {
          shuffledOrder = distractorShuffleArray(originalOrder);
        } while (
          shuffledOrder.every((val, index) => val === originalOrder[index])
        );

        return shuffledOrder;
      };

      const generateTrainCars = () => {
        const totalCars = Math.max(
          numberOfPieces.value + leadBeforeFirstPiece.value + 1,
          12
        );

        let offset;
        if (totalCars <= 12) {
          offset = -490;
        } else if (totalCars <= 15) {
          offset = -700;
        } else if (totalCars <= 18) {
          offset = -750;
        } else {
          offset = -1000;
        }

        let puzzlePieceCount = numberOfPieces.value - 1;

        const cars = Array.from({ length: totalCars }, (_, i) => {
          const isEngine = i === totalCars - 1;
          const shouldHavePuzzlePiece =
            !isEngine &&
            puzzlePieceCount >= 0 &&
            i >=
              totalCars - numberOfPieces.value - leadBeforeFirstPiece.value - 1;

          let puzzlePieceIndex = null;
          if (shouldHavePuzzlePiece) {
            puzzlePieceIndex = puzzlePieceCount;
            puzzlePieceCount--;
          } else {
            const distractorPieceIndexes = generateDistractorPieceIndexes(
              numberOfPieces.value
            );
            puzzlePieceIndex = distractorPieceIndexes[0];
          }

          const isAnswerPiece =
            shouldHavePuzzlePiece &&
            puzzlePieceIndex === numberOfPieces.value - 1;

          return {
            position:
              offset + i * (carSpacing + carLength) - spacingOffset.value,
            currentSquareIndex: 0,
            puzzlePieceComponent: PuzzlePiece,
            isDistractor: !shouldHavePuzzlePiece,
            isAnswerPiece: isAnswerPiece,
            puzzlePieceIndex: puzzlePieceIndex,
            connectorWidth: connectorWidth.value,
            connectorLeft: connectorLeft.value,
            trainCarImage: isEngine
              ? 'trainEngine.webp'
              : trainCarImages[i % trainCarImages.length],
            isClosed: false,
            reveal: false,
            isEngine: isEngine,
          };
        });

        return cars;
      };

      const handleGameEvent = async (eventType, eventMessage) => {
        isPaused.value = true;
        cancelAnimationFrame(animationFrameId);

        message.value = eventMessage;

        if (eventType === 'miss' || eventType === 'correct') {
          isGameOver.value = true;
        } else {
          await new Promise((resolve) => setTimeout(resolve, 3000));
          message.value = '';
          isPaused.value = false;
          requestAnimationFrame(startAnimation);
        }
      };

      const startAnimation = () => {
        if (isPaused.value || isGameOver.value) return;

        position.value += trainSpeed.value;
        trainCars.value.forEach((car) => (car.position += trainSpeed.value));
        wheelRotation.value += trainSpeed.value * 10;

        if (trainCars.value.every((car) => car.position > 100)) {
          cancelAnimationFrame(animationFrameId);
          document.querySelector('.puzzle-board').classList.add('shown');
          animatePuzzlePieces(numberOfPieces.value, puzzleVersion.value);
          return;
        }

        const missedAnswerPiece = checkActiveSquare();
        if (missedAnswerPiece) {
          handleGameEvent('miss', 'Oh no! You missed the puzzle piece!');
          document.querySelector('.puzzle-board').classList.add('shown');
          animatePuzzlePieces(numberOfPieces.value, puzzleVersion.value);
        } else {
          animationFrameId = requestAnimationFrame(startAnimation);
        }
      };

      const moveToMiddle = (index) => {
        cancelAnimationFrame(animationFrameId);
        const car = trainCars.value[index];
        car.position = '40';
      };

      const wheelStyle = computed(() => {
        return {
          transform: `rotate(${wheelRotation.value}deg)`,
        };
      });

      const initGame = async () => {
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
        }

        document.querySelector('.puzzle-board').classList.remove('shown');

        currentLevelData.value = levelData[level.value - 1];
        trainSpeed.value = currentLevelData.value.trainSpeed;
        numberOfPieces.value = currentLevelData.value.numberOfPieces;
        leadBeforeFirstPiece.value =
          currentLevelData.value.leadBeforeFirstPiece;

        trainLength.value = numberOfPieces.value + leadBeforeFirstPiece.value;
        target.value = Math.max(
          trainLength.value - numberOfPieces.value - leadBeforeFirstPiece.value,
          1
        );

        isTrainHidden.value = true;
        spacingOffset.value = carSpacing * Math.max(trainLength.value, 12);

        trainCars.value = generateTrainCars();

        await calculateConnectorDimensions();

        position.value = -50;
        message.value = '';
        isPaused.value = false;
        isGameOver.value = false;

        isTrainHidden.value = false;
        gameAudio.playMusic(gamePlayAudio, {
          loop: true,
          loopStart: 0.2,
          loopEnd: 4,
          fadeInDuration: 0.5,
          crossFadeDuration: 2,
        });

        startAnimation();

        await nextTick();
      };

      const resizeHandler = () => {
        calculateConnectorDimensions();
      };

      window.addEventListener('resize', resizeHandler);

      watch(level, () => {
        console.log('Level changed to:', level.value);
        localStorage.setItem('level', level.value);
      });

      const puzzleConfig = computed(() => puzzleConfigs[numberOfPieces.value]);

      const startGame = async () => {
        console.log('Starting game...');
        initGame();
        calculateConnectorDimensions();

        await nextTick();
        console.log('activepieces', document.querySelectorAll('.active-piece'));
        puzzleElements.value = Array.from(
          document.querySelectorAll('.active-piece')
        );

        ({ animatePuzzlePieces } = usePuzzlePieceAnimator(
          puzzleElements,
          puzzleVersion.value
        ));
      };

      onMounted(() => {
        wrapperRef.value = document.querySelector('.wrapper');
        treeLeftRef.value = document.querySelector('#tree-left');
        treeRightRef.value = document.querySelector('#tree-right');
      });

      onBeforeUnmount(() => {
        window.removeEventListener('resize', resizeHandler);
      });

      return {
        isContentLoaded,
        carSpacing,
        checkSquare,
        connectorWidth,
        currentLevelData,
        distractorVersion,
        getCarClass,
        getCarStyle,
        imageURL,
        isChecking,
        isPaused,
        isGameOver,
        isTrainHidden,
        level,
        levelData,
        message,
        moveToMiddle,
        puzzleVersion,
        spacingOffset,
        trainCarImages,
        trainCars,
        trainElement,
        position,
        wheelStyle,
        isDragging,
        startDrag,
        dragOffset,
        toggleDoorsOpen,
        toggleShowPopup,
        puzzleImages,
        doorsOpen,
        startGame,
        selectedImage,
        numberOfPieces,
        puzzleConfig,
        wrapperRef,
        treeLeftRef,
        treeRightRef,
        puzzlePieceStore,
      };
    },
  };
</script>
