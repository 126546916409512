<template>
  <div
    :class="[
      'puzzle-piece-container',
      `puzzle-piece-container-${puzzleVersion}-${pieceIndex + 1}`,
      { 'active-piece': !distractor },
    ]"
    :style="[containerStyle, imageVariable]"
    :data-version="puzzleVersion"
    :data-pieces="totalPieces"
  >
    <div
      :id="'piece' + (pieceIndex + 1)"
      class="puzzle-piece"
      :style="[pieceStyle, pieceSpecificStyle]"
    ></div>

    <div
      v-for="(connector, connectorIndex) in currentPiece?.connectors || []"
      :key="connectorIndex"
      :class="[
        connectorClass(
          connector,
          getConnectorGlobalIndex(pieceIndex, connectorIndex),
          allConnectors
        ),
        `connector-${puzzleVersion}`,
      ]"
      :style="[
        { backgroundImage: imageURL },
        getConnectorStyle(connector, connectorIndex),
      ]"
    >
      &nbsp;
    </div>
  </div>
</template>

<script>
  import { computed, onMounted, watch } from 'vue';
  import { usePuzzlePiece } from '@/composables/usePuzzlePiece';
  import puzzleConfigs from '@/config/puzzleConfigs';

  export default {
    name: 'PuzzlePiece',
    props: {
      pieceIndex: {
        type: Number,
        required: true,
      },
      imageURL: {
        type: String,
        required: true,
      },
      puzzleVersion: {
        type: String,
        required: true,
        validator: (value) => ['v1', 'v2', 'v3'].includes(value),
      },
      distractor: {
        type: Boolean,
        default: false,
      },
      totalPieces: {
        type: Number,
        required: true,
        validator: (value) => value >= 2 && value <= 10,
      },
    },
    setup(props) {
      console.log('PuzzlePiece setup', props);
      const piecesConfig = computed(
        () => puzzleConfigs[props.totalPieces ?? 2][props.puzzleVersion ?? "v2"]
      );

      const styles = computed(() => puzzleConfigs[props.totalPieces ?? 2].styles);

      const {
        currentPiece,
        pieceStyle,
        connectorClass,
        getConnectorGlobalIndex,
        imageVariable,
        allConnectors,
      } = usePuzzlePiece(props, piecesConfig.value);

      const containerStyle = computed(() => styles.value.puzzleContainer || {});

      const pieceSpecificStyle = computed(() => {
        const baseStyles = styles.value.piece;
        const pieceConfig = piecesConfig.value[props.pieceIndex];
        return {
          ...(typeof baseStyles === 'function'
            ? baseStyles(props.pieceIndex)
            : baseStyles[`&:nth-child(${props.pieceIndex + 1})`] || {}),
          width: `${pieceConfig.width}px`,
          height: `${pieceConfig.height}px`,
        };
      });

      const connectorStyles = computed(
        () => puzzleConfigs[props.totalPieces ?? 2].styles.connectors || {}
      );

      const getConnectorStyle = (connector, index) => {
        const genericClassName = `.connector-${connector}${
          index > 0 ? index : ''
        }`;
        const versionSpecificClassName = `${genericClassName}.connector-${props.puzzleVersion ?? 'v2'}`;

        let styleObj =
          connectorStyles.value[versionSpecificClassName] ||
          connectorStyles.value[genericClassName];

        // Ensure styleObj is an object
        if (typeof styleObj !== 'object' || styleObj === null) {
          console.error('styleObj is not an object:', styleObj);
          return {};
        }

        // Convert camelCase to kebab-case
        const styleObject = Object.entries(styleObj).reduce(
          (acc, [key, value]) => {
            const kebabKey = key.replace(/([A-Z])/g, '-$1').toLowerCase();
            acc[kebabKey] = value;
            return acc;
          },
          {}
        );

        return styleObject;
      };

      onMounted(() => {
        // Any mounting logic
      });

      watch(currentPiece, (newVal) => {
        console.log('currentPiece updated:', newVal);
      });

      watch(
        () => props.pieceIndex,
        (newVal) => {
          console.log('pieceIndex updated:', newVal);
        }
      );

      return {
        currentPiece,
        pieceStyle,
        connectorClass,
        getConnectorGlobalIndex,
        imageVariable,
        allConnectors,
        containerStyle,
        pieceSpecificStyle,
        getConnectorStyle,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/connectors.scss';

  .puzzle-piece-container {
    position: relative;
  }

  .puzzle-piece {
    position: absolute;
    background-size: cover;
    mask-size: cover;
  }

  // Note: Specific connector styles are now applied dynamically in the template
</style>
