import { computed } from 'vue';

export const usePieceStyle = (currentPiece, props) => {
  const pieceStyle = computed(() => {
    if (!currentPiece.value) return {};
    return {
      backgroundImage: `url(${props.imageURL})`,
      backgroundPosition: `${currentPiece.value.position.left || '0px'} ${
        currentPiece.value.position.top || '0px'
      }`,
      backgroundSize: '400px 400px',
      maskImage: `url(${currentPiece.value.maskImage})`,
      WebkitMaskImage: `url(${currentPiece.value.maskImage})`,
      width: currentPiece.value.width || '200px',
      height: currentPiece.value.height || '200px',
    };
  });

  return { pieceStyle };
};
