// useCheckSquare.js
import { ref } from 'vue';
import { usePuzzlePieceStore } from '@/stores/puzzlePieceStore';

export const useCheckSquare = (
  trainCarsRef,
  leadBeforeFirstPieceRef,
  spacingOffsetRef,
  wrapperRef,
  treeLeftRef,
  treeRightRef
) => {
  const puzzlePieceStore = usePuzzlePieceStore();
  const isChecking = ref(false);

  const getActiveCarIndex = () => {
    if (wrapperRef.value && treeLeftRef.value && treeRightRef.value) {
      const wrapperRect = wrapperRef.value.getBoundingClientRect();
      const treeLeftRect = treeLeftRef.value.getBoundingClientRect();
      const treeRightRect = treeRightRef.value.getBoundingClientRect();
      const treeLeftRight =
        treeLeftRect.right - wrapperRect.left - spacingOffsetRef.value + 6; // Assuming carSpacing is 0.2, 0.2 * 6 = 1.2
      const treeRightLeft = treeRightRect.left - wrapperRect.left;

      return trainCarsRef.value.findIndex((car) => {
        const carLeft = (car.position / 100) * wrapperRect.width;
        const carRight = carLeft + (50 / 100) * wrapperRect.width - 120;

        return carRight >= treeLeftRight && carLeft <= treeRightLeft;
      });
    }
    return -1;
  };

  const checkActiveSquare = () => {
    const activeCarIndex = getActiveCarIndex();
    const trainCarIndex = activeCarIndex + leadBeforeFirstPieceRef.value;

    if (
      trainCarsRef.value[trainCarIndex] !== undefined &&
      trainCarsRef.value[trainCarIndex].isAnswerPiece
    ) {
      trainCarsRef.value[trainCarIndex].isClosed = true;
      return true;
    }

    if (
      activeCarIndex < trainCarsRef.value.length &&
      trainCarsRef.value[trainCarIndex] !== undefined
    ) {
      trainCarsRef.value[trainCarIndex].isClosed = true;
    }

    return false;
  };

  const checkSquare = async () => {
    if (isChecking.value || puzzlePieceStore.isGameOver) return;

    const activeCarIndex = getActiveCarIndex();
    const adjustedActiveCarIndex = activeCarIndex + 1;

    const trainCar = trainCarsRef.value[adjustedActiveCarIndex];
    if (activeCarIndex !== -1 && trainCar) {
      isChecking.value = true;

      if (trainCar.puzzlePieceComponent) {
        if (trainCar.isAnswerPiece) {
          await puzzlePieceStore.handleGameEvent(
            'correct',
            'Correct! You solved the puzzle!'
          );
        } else {
          await puzzlePieceStore.handleGameEvent(
            'too_soon',
            'Not yet! Keep trying!'
          );
        }
      } else {
        await puzzlePieceStore.handleGameEvent('too_soon', 'No puzzle here!');
      }

      isChecking.value = false;
    }
  };

  return {
    isChecking,
    checkSquare,
    checkActiveSquare,
    getActiveCarIndex,
  };
};
