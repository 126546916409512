import { defineStore } from 'pinia';

export const usePuzzlePieceStore = defineStore('game', {
  state: () => ({
    isGameOver: false,
    trainCars: [],
    message: '',
    isPaused: false,
  }),
  actions: {
    handleGameEvent(eventType, eventMessage) {
      this.isPaused = true;
      this.message = eventMessage;

      if (eventType === 'miss' || eventType === 'correct') {
        this.isGameOver = true;
      } else {
        setTimeout(() => {
          this.message = '';
          this.isPaused = false;
        }, 3000);
      }
    },
    setTrainCars(cars) {
      this.trainCars = cars;
    },
    resetGame() {
      this.isGameOver = false;
      this.trainCars = [];
      this.message = '';
      this.isPaused = false;
    },
  },
});
