// puzzleConfigs.js

import {
  requireMask,
  requireMaskSync,
  requireImage,
  requireImageSync,
} from '@/utils/assetLoader';

import {
  connectorTop,
  connectorRight,
  connectorBottom,
  connectorLeft,
  connectorLeft45,
  connectorRight45,
  connectorRight45Down,
} from '@/utils/connectorStyles';

export default {
  2: {
    v1: [
      {
        connectors: [],
        maskImage: requireMaskSync('2/1-left.svg'),
        position: { top: '0px', left: '0px' },
        width: 200,
        height: 400,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('2/solid.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 400,
      },
    ],
    v2: [
      {
        connectors: ['right'],
        maskImage: requireMaskSync('2/2-left.svg'),
        position: { top: '0px', left: '0px' },
        width: 200,
        height: 400,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('2/2-right.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 400,
      },
    ],
    v3: [
      {
        connectors: ['right'],
        maskImage: requireMaskSync('2/3-left.svg'),
        position: { top: '0px', left: '0px' },
        width: 200,
        height: 400,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('2/3-right.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 400,
      },
    ],
    v4: [
      {
        connectors: ['right'],
        maskImage: require('@/assets/masks/2/solid.svg'),
        position: { top: '0px', left: '0px' },
        width: 200,
        height: 400,
      },
      {
        connectors: [],
        maskImage: require('@/assets/masks/2/4-right.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 400,
      },
    ],
    v5: [
      {
        connectors: [],
        maskImage: require('@/assets/masks/2/5-left.svg'),
        position: { top: '0px', left: '0px' },
        width: 200,
        height: 400,
      },
      {
        connectors: ['left'],
        maskImage: require('@/assets/masks/2/solid.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 400,
      },
    ],
    v6: [
      {
        connectors: [],
        maskImage: require('@/assets/masks/2/6-top.svg'),
        position: { top: '0px', left: '0px' },
        width: 400,
        height: 200,
      },
      {
        connectors: ['top'],
        maskImage: require('@/assets/masks/2/solid-horz.svg'),
        position: { top: '200px', left: '0px' },
        width: 400,
        height: 200,
      },
    ],
    v7: [
      {
        connectors: ['bottom'],
        maskImage: require('@/assets/masks/2/solid-horz.svg'),
        position: { top: '0px', left: '0px' },
        width: 400,
        height: 200,
      },
      {
        connectors: [],
        maskImage: require('@/assets/masks/2/7-bottom.svg'),
        position: { top: '200px', left: '0px' },
        width: 400,
        height: 200,
      },
    ],
    v8: [
      {
        connectors: ['right', 'right'],
        maskImage: require('@/assets/masks/2/8-left.svg'),
        position: { top: '0px', left: '0px' },
        width: 200,
        height: 400,
      },
      {
        connectors: ['left'],
        maskImage: require('@/assets/masks/2/8-right.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 400,
      },
    ],
    v9: [
      {
        connectors: ['right'],
        maskImage: require('@/assets/masks/2/9-left.svg'),
        position: { top: '0px', left: '0px' },
        width: 200,
        height: 400,
      },
      {
        connectors: ['left', 'left'],
        maskImage: require('@/assets/masks/2/9-right.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 400,
      },
    ],
    styles: {
      puzzleContainer: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      piece: {
        '&[data-version^="v1"], &[data-version^="v2"], &[data-version^="v3"], &[data-version^="v4"], &[data-version^="v5"], &[data-version^="v8"], &[data-version^="v9"]': {
          width: '200px',
          height: '400px',
        },
        '&[data-version^="v6"], &[data-version^="v7"]': {
          width: '400px',
          height: '200px',
        },
      },
      connectors: {
        '.connector-left': {
          ...connectorLeft(2),
          backgroundPosition: '-128px -55px',
          top: '108px',
          left: '-72px',
        },
        '.connector-left.connector-v1': {
          ...connectorLeft(2),
          backgroundPosition: '-128px -168px',
          top: '208px',
          left: '-72px',
        },
                '.connector-right.connector-v2': {
          ...connectorRight(2),
          backgroundPosition: '-200px -269px',
          top: '308px',
          left: '200px',
        },
        '.connector-right.connector-v4': {
          ...connectorRight(2),
          backgroundPosition: '-199px -170px',
          top: '210px',
          right: '0',
          left: '200px',
        },
        '.connector-left.connector-v5': {
          ...connectorLeft(2),
          backgroundPosition: '-128px -168px',
          top: '209px',
          right: '0',
          left: '-72px',
        },
        '.connector-top.connector-v6': {
          ...connectorTop(2),
          backgroundPosition: '-143px -102px',
          top: '-38px',
          left: '-236px',
        },
        '.connector-bottom.connector-v7': {
          ...connectorBottom(2),
          backgroundPosition: '-145px -167px',
          bottom: '-69px',
          left: '203px',
        },
        '.connector-left.connector-v8': {
          ...connectorLeft(2),
          backgroundPosition: '-109px -149px',
          top: '204px',
          left: '-74px',
        },
        '.connector-right.connector-v8': {
          ...connectorRight(2),
          backgroundPosition: '-180px -245px',
          top: '312px',
          left: '202px',
        },
        '.connector-right1.connector-v8': {
          ...connectorRight(2),
          backgroundPosition: '-201px -60px',
          top: '100px',
          right: '0',
          left: '200px',
        },
        '.connector-right.connector-v9': {
          ...connectorRight(2),
          backgroundPosition: '-178px -146px',
          top: '202px',
          left: '201px',
        },
        '.connector-left.connector-v9': {
          ...connectorLeft(2),
          backgroundPosition: '-108px -51px',
          top: '95px',
          left: '-74px',
        },
        '.connector-left1.connector-v9': {
          ...connectorLeft(2),
          backgroundPosition: '-107px -246px',
          top: '312px',
          left: '-74px',
        },
      },
    },
  },
  3: {
    v1: [
      {
        connectors: ['right'],
        maskImage: requireMaskSync('3/1-left.svg'),
        position: { top: '0px', left: '0px' },
        width: 200,
        height: 400,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('3/blank-square.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('3/1-right-bottom.svg'),
        position: { top: '-200px', left: '-200px' },
        width: 200,
        height: 200,
      },
    ],
    v2: [
      {
        connectors: ['right'],
        maskImage: requireMaskSync('3/blank-square.svg'),
        position: { top: '0px', left: '0px' },
        width: 200,
        height: 200,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('3/2-right.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 400,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('3/2-left-bottom.svg'),
        position: { top: '-200px', left: '0' },
        width: 200,
        height: 200,
      },
    ],
    v3: [
      {
        connectors: [],
        maskImage: requireMaskSync('3/3-left-top.svg'),
        position: { top: '0px', left: '0px' },
        width: 200,
        height: 200,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('3/blank-rectangle.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 400,
      },
      {
        connectors: ['top'],
        maskImage: requireMaskSync('3/3-left-bottom.svg'),
        position: { top: '-200px', left: '0' },
        width: 200,
        height: 200,
      },
    ],
    styles: {
      puzzleContainer: {
        gridTemplateAreas: '"one two" ". three"',
      },
      piece: {
        '&:nth-child(1)': { gridArea: 'one' },
        '&:nth-child(2)': { gridArea: 'two' },
        '&:nth-child(3)': { gridArea: 'three' },
      },
      connectors: {
        '.connector-left': {
          ...connectorLeft(3),
          backgroundPosition: '-144px -267px',
          top: '300px',
          left: '-73px',
        },
        '.connector-right': {
          ...connectorRight(3),
          backgroundPosition: '-224px -268px',
          top: '300px',
          left: '200px',
        },
        '.connector-top': {
          ...connectorTop(3),
          backgroundPosition: '-75px -129px',
          top: '-36px',
          left: '68px',
          width: '79px',
          height: '72px',
        },
      },
    },
  },
  4: {
    v1: [
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('4/1-top-left.svg'),
        position: { top: '0px', left: '0px' },
        width: 200,
        height: 200,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('4/1-top-right.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 200,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('4/1-bottom-left.svg'),
        position: { top: '-200px', left: '0px' },
        width: 200,
        height: 200,
      },
      {
        connectors: ['top'],
        maskImage: requireMaskSync('4/1-bottom-right.svg'),
        position: { top: '-200px', left: '-200px' },
        width: 200,
        height: 200,
      },
    ],
    v2: [
      {
        connectors: ['right', 'bottom'],
        maskImage: requireMaskSync('4/general-square.svg'),
        position: { top: '0px', left: '0px' },
        width: '200px',
        height: '200px',
      },
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('4/2-top-right.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('4/2-bottom-left.svg'),
        position: { top: '-200px', left: '0px' },
        width: 200,
        height: 200,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('4/2-bottom-right.svg'),
        position: { top: '-200px', left: '-200px' },
        width: 200,
        height: 200,
      },
    ],
    v3: [
      {
        connectors: ['right'],
        maskImage: requireMaskSync('4/3-top-left.svg'),
        position: { top: '0px', left: '0px' },
        width: 200,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('4/3-top-right.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 200,
      },
      {
        connectors: ['top'],
        maskImage: requireMaskSync('4/3-bottom-right.svg'),
        position: { top: '-200px', left: '0px' },
        width: 200,
        height: 200,
      },
      {
        connectors: ['top', 'left'],
        maskImage: requireMaskSync('4/general-square.svg'),
        position: { top: '-200px', left: '-200px' },
        width: 200,
        height: 200,
      },
    ],
    styles: {
      puzzleContainer: {
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
      },
      piece: {
        width: 200,
        height: 200,
      },
      connectors: {
        '.connector-left': {
          ...connectorLeft(4),
          backgroundPosition: '-131px -316px',
          width: '81px',
          height: '73px',
          left: '-81px',
          top: '152px',
        },
        '.connector-bottom': {
          ...connectorBottom(4),
          backgroundPosition: '-75px -199px',
        },
        '.connector-bottom1': {
          ...connectorBottom(4),
          backgroundPosition: '-296px -199px',
        },
        '.connector-top': {
          ...connectorTop(4),
          backgroundPosition: '-75px -119px',
        },
        '.connector-right': {
          ...connectorRight(4),
          right: '-85px',
          top: '100px',
          backgroundPosition: '-222px -61px',
          width: '86px',
          height: '76px',
        },
      },
    },
  },
  5: {
    v1: [
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('5/1-left-top.svg'),
        position: { top: '0px', left: '0px' },
        width: 200,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('5/1-right-top.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 200,
      },
      {
        connectors: ['top'],
        maskImage: requireMaskSync('5/1-mid.svg'),
        position: { top: '-100px', left: '-100px' },
        width: 200,
        height: 100,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('5/1-left-bottom.svg'),
        position: { top: '-200px', left: '0' },
        width: 200,
        height: 200,
      },
      {
        connectors: ['left', 'left'],
        maskImage: requireMaskSync('5/1-right-bottom.svg'),
        position: { top: '-200px', left: '-200px' },
        width: 200,
        height: 200,
      },
    ],
    v2: [
      {
        connectors: ['right'],
        maskImage: requireMaskSync('5/2-left-top.svg'),
        position: { top: '0px', left: '0px' },
        width: 200,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('5/2-right-top.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 200,
      },
      {
        connectors: ['top', 'left'],
        maskImage: requireMaskSync('5/2-mid.svg'),
        position: { top: '-100px', left: '-130px' },
        width: 200,
        height: 247,
      },
      {
        connectors: ['top'],
        maskImage: requireMaskSync('5/2-left-bottom.svg'),
        position: { top: '-200px', left: '0' },
        width: 200,
        height: 200,
      },
      {
        connectors: ['left', 'top'],
        maskImage: requireMaskSync('5/2-right-bottom.svg'),
        position: { top: '-200px', left: '-200px' },
        width: 200,
        height: 200,
      },
    ],
    v3: [
      {
        connectors: [],
        maskImage: requireMaskSync('5/3-left-top.svg'),
        position: { top: '0px', left: '0px' },
        width: 200,
        height: 200,
      },
      {
        connectors: ['bottom', 'bottom'],
        maskImage: requireMaskSync('5/3-right-top.svg'),
        position: { top: '0px', left: '-200px' },
        width: 200,
        height: 200,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('5/3-mid.svg'),
        position: { top: '-100px', left: '-100px' },
        width: '200px',
        height: '247px',
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('5/3-left-bottom.svg'),
        position: { top: '-200px', left: '0' },
        width: 200,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('5/3-right-bottom.svg'),
        position: { top: '-200px', left: '-200px' },
        width: 200,
        height: 200,
      },
    ],
    styles: {
      puzzleContainer: {
        gridTemplateAreas: '"one two" ". three" "four five"',
      },
      piece: {
        '&:nth-child(1)': { gridArea: 'one' },
        '&:nth-child(2)': { gridArea: 'two' },
        '&:nth-child(3)': {
          gridArea: 'three',
          position: 'absolute',
          top: '-100px',
          left: '-100px',
        },
        '&:nth-child(4)': { gridArea: 'four' },
        '&:nth-child(5)': { gridArea: 'five' },
      },
      connectors: {
        '.connector-left': {
          ...connectorLeft(5),
          backgroundPosition: '-256px -228px',
          top: '62px',
          left: '30px',
        },
        '.connector-left1': {
          ...connectorLeft(5),
          backgroundPosition: '-151px -328px',
          top: '161px',
          left: '-71px',
        },
        '.connector-right': {
          ...connectorRight(5),
          backgroundPosition: '-224px -268px',
          top: '300px',
          left: '200px',
        },
        '.connector-top': {
          ...connectorTop(5),
          backgroundPosition: '-264px -32px',
          top: '-132px',
          left: '33px',
        },
        '.connector-bottom': {
          ...connectorBottom(5),
          backgroundPosition: '-14px -199px',
          top: '200px',
          left: '45px',
        },
      },
    },
  },
  6: {
    v1: [
      {
        connectors: ['right'],
        maskImage: requireMaskSync('6/1-top-left.svg'),
        position: { top: '0px', left: '0px' },
        width: 133,
        height: 200,
      },
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('6/1-top-mid.svg'),
        position: { top: '0px', left: '-133px' },
        width: 133,
        height: 200,
      },
      {
        connectors: ['left', 'bottom'],
        maskImage: requireMaskSync('6/general-square.svg'),
        position: { top: '0px', left: '-265px' },
        width: 133,
        height: 200,
      },
      {
        connectors: ['top', 'right'],
        maskImage: requireMaskSync('6/general-square.svg'),
        position: { top: '-200px', left: '0' },
        width: 133,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('6/1-bottom-mid.svg'),
        position: { top: '-200px', left: '-133px' },
        width: 133,
        height: 200,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('6/1-bottom-right.svg'),
        position: { top: '-200px', left: '-267px' },
        width: 133,
        height: 200,
      },
    ],
    v2: [
      {
        connectors: [],
        maskImage: requireMaskSync('6/2-top-left.svg'),
        position: { top: '0px', left: '0px' },
        width: 133,
        height: 200,
      },
      {
        connectors: ['bottom', 'left', 'right'],
        maskImage: requireMaskSync('6/general-square.svg'),
        position: { top: '0px', left: '-133px' },
        width: 133,
        height: 200,
      },
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('6/2-top-right.svg'),
        position: { top: '0px', left: '-265px' },
        width: 133,
        height: 200,
      },
      {
        connectors: ['top', 'right'],
        maskImage: requireMaskSync('6/2-bottom-left.svg'),
        position: { top: '-200px', left: '0' },
        width: 133,
        height: 200,
      },
      {
        connectors: ['left', 'right'],
        maskImage: requireMaskSync('6/2-bottom-mid.svg'),
        position: { top: '-200px', left: '-133px' },
        width: 133,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('6/2-bottom-right.svg'),
        position: { top: '-200px', left: '-265px' },
        width: 133,
        height: 200,
      },
    ],
    v3: [
      {
        connectors: ['right'],
        maskImage: requireMaskSync('6/3-top-left.svg'),
        position: { top: '0px', left: '0px' },
        width: 133,
        height: 200,
      },
      {
        connectors: ['bottom', 'right'],
        maskImage: requireMaskSync('6/3-top-mid.svg'),
        position: { top: '0px', left: '-133px' },
        width: 133,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('6/3-top-right.svg'),
        position: { top: '0px', left: '-265px' },
        width: 133,
        height: 200,
      },
      {
        connectors: ['top'],
        maskImage: requireMaskSync('6/3-bottom-left.svg'),
        position: { top: '-200px', left: '0' },
        width: 133,
        height: 200,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('6/3-bottom-mid.svg'),
        position: { top: '-200px', left: '-133px' },
        width: 133,
        height: 200,
      },
      {
        connectors: ['left', 'top'],
        maskImage: requireMaskSync('6/general-square.svg'),
        position: { top: '-200px', left: '-265px' },
        width: 133,
        height: 200,
      },
    ],
    styles: {
      puzzleContainer: {
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
      },
      piece: {
        width: 'calc(var(--container-size) / 3)',
        height: '200px',
      },
      connectors: {
        '.connector-left': {
          ...connectorLeft(6),
          backgroundPosition: '-254px -45px',
          top: '59px',
          left: '-42px',
        },
        '.connector-right': {
          ...connectorRight(6),
          backgroundPosition: '-146px -119px',
          top: '142px',
          right: '-44px',
        },
        '.connector-top': {
          ...connectorTop(6),
          backgroundPosition: '-51px -159px',
          top: '-22px',
          left: '47px',
        },
        '.connector-bottom': {
          ...connectorBottom(6),
          backgroundPosition: '-226px -200px',
          bottom: '-43px',
          left: '95px',
        },
      },
    },
  },
  7: {
    v1: [
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('7/1-left-top.svg'),
        position: { top: '0px', left: '2px' },
        width: 163,
        height: 241,
      },
      {
        connectors: ['left', 'bottom'],
        maskImage: requireMaskSync('7/1-mid-top.svg'),
        position: { top: '0px', left: '-122px' },
        width: 158,
        height: 173,
      },
      {
        connectors: ['left45'],
        maskImage: requireMaskSync('7/1-right-top.svg'),
        position: { top: '0', left: '-238px' },
        width: 167,
        height: 200,
      },
      {
        connectors: ['bottom', 'left45'],
        maskImage: requireMaskSync('7/1-mid-mid.svg'),
        position: { top: '-136px', left: '-120px' },
        width: 158,
        height: 150,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('7/1-left-bottom.svg'),
        position: { top: '-200px', left: '0' },
        width: 163,
        height: 200,
      },
      {
        connectors: ['right45'],
        maskImage: requireMaskSync('7/1-mid-bottom.svg'),
        position: { top: '-264px', left: '-122px' },
        width: 171,
        height: 136,
      },
      {
        connectors: ['top'],
        maskImage: requireMaskSync('7/1-right-bottom.svg'),
        position: { top: '-200px', left: '-238px' },
        width: 161.7,
        height: 201.4,
      },
    ],
    v2: [
      {
        connectors: ['right'],
        maskImage: requireMaskSync('7/2-left-top.svg'),
        position: { top: '0px', left: '2px' },
        width: 163,
        height: 241,
      },
      {
        connectors: ['right', 'right45Down'],
        maskImage: requireMaskSync('7/2-mid-top.svg'),
        position: { top: '0px', left: '-122px' },
        width: 158,
        height: 173,
      },
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('7/2-right-top.svg'),
        position: { top: '0', left: '-238px' },
        width: 167,
        height: 200,
      },
      {
        connectors: ['top', 'right45Down'],
        maskImage: requireMaskSync('7/2-mid-mid.svg'),
        position: { top: '-136px', left: '-120px' },
        width: 158,
        height: 150,
      },
      {
        connectors: ['top', 'right'],
        maskImage: requireMaskSync('7/2-left-bottom.svg'),
        position: { top: '-200px', left: '0' },
        width: 163,
        height: 200,
      },
      {
        connectors: ['top', 'right'],
        maskImage: requireMaskSync('7/2-mid-bottom.svg'),
        position: { top: '-264px', left: '-122px' },
        width: 171,
        height: 136,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('7/2-right-bottom.svg'),
        position: { top: '-200px', left: '-238px' },
        width: 161.7,
        height: 201.4,
      },
    ],
    v3: [
      {
        connectors: ['right'],
        maskImage: requireMaskSync('7/2-left-top.svg'),
        position: { top: '0px', left: '2px' },
        width: 163,
        height: 241,
      },
      {
        connectors: ['right', 'right45Down'],
        maskImage: requireMaskSync('7/2-mid-top.svg'),
        position: { top: '0px', left: '-122px' },
        width: 158,
        height: 173,
      },
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('7/2-right-top.svg'),
        position: { top: '0', left: '-238px' },
        width: 167,
        height: 200,
      },
      {
        connectors: ['top', 'right45Down'],
        maskImage: requireMaskSync('7/2-mid-mid.svg'),
        position: { top: '-136px', left: '-120px' },
        width: 158,
        height: 150,
      },
      {
        connectors: ['top', 'right'],
        maskImage: requireMaskSync('7/2-left-bottom.svg'),
        position: { top: '-200px', left: '0' },
        width: 163,
        height: 200,
      },
      {
        connectors: ['top', 'right'],
        maskImage: requireMaskSync('7/2-mid-bottom.svg'),
        position: { top: '-264px', left: '-122px' },
        width: 171,
        height: 136,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('7/2-right-bottom.svg'),
        position: { top: '-200px', left: '-238px' },
        width: 161.7,
        height: 201.4,
      },
    ],
    styles: {
      puzzleContainer: {
        gridTemplateAreas: '"one two three" ". four ." "five six seven"',
      },
      piece: {
        '&:nth-child(1)': { gridArea: 'one', width: '163px', height: '241px' },
        '&:nth-child(2)': {
          gridArea: 'two',
          width: '158px',
          height: '173px',
          left: '-39px',
        },
        '&:nth-child(3)': {
          gridArea: 'three',
          width: '167px',
          height: '200px',
          left: '-96px',
        },
        '&:nth-child(4)': {
          gridArea: 'four',
          width: '158px',
          height: '150px',
          top: '-107px',
          left: '-41px',
          position: 'absolute',
        },
        '&:nth-child(5)': {
          gridArea: 'five',
          width: '163px',
          top: '-42px',
          left: '2px',
        },
        '&:nth-child(6)': {
          gridArea: 'six',
          width: '171px',
          height: '136px',
          top: '22px',
          left: '-39px',
        },
        '&:nth-child(7)': {
          gridArea: 'seven',
          width: '161.7px',
          height: '201.4px',
          top: '-42px',
          left: '-96px',
        },
      },
      connectors: {
        '.connector-left': {
          ...connectorLeft(7),
          backgroundPosition: '-90px -14px',
          top: '35px',
          left: '-42px',
        },
        '.connector-right': {
          ...connectorRight(7),
          backgroundPosition: '-134px -13px',
          top: '34px',
          right: '-6px',
          'z-index': 1000,
        },
        '.connector-top': {
          ...connectorTop(7),
          backgroundPosition: '-200px -95px',
          top: '-125px',
          left: '21px',
        },
        '.connector-bottom': {
          ...connectorBottom(7),
          backgroundPosition: '-354px -200px',
          bottom: '0px',
          left: '9px',
        },
        '.connector-left45': {
          ...connectorLeft45(7),
          backgroundPosition: '-243px -72px',
          top: '94px',
          left: '-110px',
        },
        '.connector-right45': {
          ...connectorRight45(7),
          backgroundPosition: '-276px -274px',
          top: '49px',
          left: '87px',
        },
        '.connector-right45Down': {
          ...connectorRight45Down(7),
          backgroundPosition: '-278px -87px',
          top: '110px',
          right: '-3px',
        },
      },
    },
  },
  8: {
    v1: [
      {
        connectors: ['right', 'bottom'],
        maskImage: requireMaskSync('8/blank.svg'),
        position: { top: '0', left: '0px' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('8/1-top-2.svg'),
        position: { top: '0', left: '-100px' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('8/1-top-3.svg'),
        position: { top: '0', left: '-200px' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('8/1-top-4.svg'),
        position: { top: '0', left: '-300px' },
        width: 100,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('8/1-bottom-1.svg'),
        position: { top: '-200px', left: '0' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['left', 'top', 'right'],
        maskImage: requireMaskSync('8/blank.svg'),
        position: { top: '-200px', left: '-100px' },
        width: 100,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('8/1-bottom-3.svg'),
        position: { top: '-200px', left: '-200px' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('8/1-bottom-4.svg'),
        position: { top: '-200px', left: '-300px' },
        width: 100,
        height: 200,
      },
    ],
    v2: [
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('8/2-top-1.svg'),
        position: { top: '0', left: '0px' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['left', 'right', 'bottom'],
        maskImage: requireMaskSync('8/blank.svg'),
        position: { top: '0', left: '-100px' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['right', 'bottom'],
        maskImage: requireMaskSync('8/2-top-3.svg'),
        position: { top: '0', left: '-200px' },
        width: 100,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('8/2-top-4.svg'),
        position: { top: '0', left: '-300px' },
        width: 100,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('8/2-bottom-1.svg'),
        position: { top: '-200px', left: '0' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['left', 'right'],
        maskImage: requireMaskSync('8/2-bottom-2.svg'),
        position: { top: '-200px', left: '-100px' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('8/2-bottom-3.svg'),
        position: { top: '-200px', left: '-200px' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['top'],
        maskImage: requireMaskSync('8/2-bottom-4.svg'),
        position: { top: '-200px', left: '-300px' },
        width: 100,
        height: 200,
      },
    ],
    v3: [
      {
        connectors: [],
        maskImage: requireMaskSync('8/3-top-1.svg'),
        position: { top: '0', left: '0px' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['left', 'right'],
        maskImage: requireMaskSync('8/blank.svg'),
        position: { top: '0', left: '-100px' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('8/3-top-3.svg'),
        position: { top: '0', left: '-200px' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('8/3-top-4.svg'),
        position: { top: '0', left: '-300px' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['top'],
        maskImage: requireMaskSync('8/3-bottom-1.svg'),
        position: { top: '-200px', left: '0' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['top', 'left', 'right'],
        maskImage: requireMaskSync('8/blank.svg'),
        position: { top: '-200px', left: '-100px' },
        width: 100,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('8/3-bottom-3.svg'),
        position: { top: '-200px', left: '-200px' },
        width: 100,
        height: 200,
      },
      {
        connectors: ['top'],
        maskImage: requireMaskSync('8/blank.svg'),
        position: { top: '-200px', left: '-300px' },
        width: 100,
        height: 200,
      },
    ],
    styles: {
      puzzleContainer: {
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
      },
      piece: {
        width: 'calc(var(--container-size) / 4)',
        height: '200px',
      },
      connectors: {
        '.connector-left': {
          ...connectorLeft(8),
          backgroundPosition: '-70px -334px',
          top: '157px',
          left: '-44px',
        },
        '.connector-right': {
          ...connectorRight(8),
          backgroundPosition: '-111px -56px',
          top: '76px',
          right: '-47px',
        },
        '.connector-top': {
          ...connectorTop(8),
          backgroundPosition: '-147px -159px',
          top: '-20px',
          left: '32px',
        },
        '.connector-bottom': {
          ...connectorBottom(8),
          backgroundPosition: '-36px -200px',
          bottom: '-40px',
          left: '56px',
        },
      },
    },
  },
  9: {
    v1: [
      {
        connectors: ['right'],
        maskImage: requireMaskSync('9/blank.svg'),
        position: { top: '0', left: '0px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('9/1-top-2.svg'),
        position: { top: '0', left: '-133px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('9/1-top-3.svg'),
        position: { top: '0', left: '-266px' },
        width: 134,
        height: 134,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('9/1-mid-1.svg'),
        position: { top: '-133px', left: '0' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['bottom', 'left'],
        maskImage: requireMaskSync('9/1-mid-2.svg'),
        position: { top: '-133px', left: '-133px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['left', 'top'],
        maskImage: requireMaskSync('9/1-mid-3.svg'),
        position: { top: '-133px', left: '-266px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['top', 'right'],
        maskImage: requireMaskSync('9/blank.svg'),
        position: { top: '-266px', left: '0' },
        width: 134,
        height: 134,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('9/1-bottom-2.svg'),
        position: { top: '-266px', left: '-133px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['top', 'left'],
        maskImage: requireMaskSync('9/blank.svg'),
        position: { top: '-266px', left: '-266px' },
        width: 134,
        height: 134,
      },
    ],
    v2: [
      {
        connectors: ['right', 'bottom'],
        maskImage: requireMaskSync('9/blank.svg'),
        position: { top: '0', left: '0px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('9/2-top-2.svg'),
        position: { top: '0', left: '-133px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('9/2-top-3.svg'),
        position: { top: '0', left: '-266px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['bottom', 'right'],
        maskImage: requireMaskSync('9/2-mid-1.svg'),
        position: { top: '-133px', left: '0' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['bottom', 'right'],
        maskImage: requireMaskSync('9/2-mid-2.svg'),
        position: { top: '-133px', left: '-133px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['top'],
        maskImage: requireMaskSync('9/2-mid-3.svg'),
        position: { top: '-133px', left: '-266px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('9/2-bottom-1.svg'),
        position: { top: '-266px', left: '0' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('9/2-bottom-2.svg'),
        position: { top: '-266px', left: '-133px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['top'],
        maskImage: requireMaskSync('9/2-bottom-3.svg'),
        position: { top: '-266px', left: '-266px' },
        width: 134,
        height: 134,
      },
    ],
    v3: [
      {
        connectors: ['right'],
        maskImage: requireMaskSync('9/3-top-1.svg'),
        position: { top: '0', left: '0px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['bottom', 'right'],
        maskImage: requireMaskSync('9/3-top-2.svg'),
        position: { top: '0', left: '-133px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('9/3-top-3.svg'),
        position: { top: '0', left: '-266px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['bottom', 'top'],
        maskImage: requireMaskSync('9/3-mid-1.svg'),
        position: { top: '-133px', left: '0' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['left', 'right'],
        maskImage: requireMaskSync('9/3-mid-2.svg'),
        position: { top: '-133px', left: '-133px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('9/3-mid-3.svg'),
        position: { top: '-133px', left: '-266px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('9/3-bottom-1.svg'),
        position: { top: '-266px', left: '0' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('9/3-bottom-2.svg'),
        position: { top: '-266px', left: '-133px' },
        width: 134,
        height: 134,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('9/3-bottom-3.svg'),
        position: { top: '-266px', left: '-266px' },
        width: 134,
        height: 134,
      },
    ],
    styles: {
      puzzleContainer: {
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: 'repeat(3, 1fr)',
      },
      piece: {
        width: 'calc(402px / 3)',
        height: 'calc(402px / 3)',
      },
      connectors: {
        '.connector-left': {
          ...connectorLeft(9),
          backgroundPosition: '-244px -22px',
          top: '38px',
          left: '-47px',
        },
        '.connector-right': {
          ...connectorRight(9),
          backgroundPosition: '-148px -45px',
          top: '67px',
          right: '-53px',
        },
        '.connector-top': {
          ...connectorTop(9),
          backgroundPosition: '-313px -73px',
          top: '-21px',
          left: '12px',
          height: '78px',
        },
        '.connector-bottom': {
          ...connectorBottom(9),
          backgroundPosition: '-202px -133px',
          bottom: '-43px',
          left: '74px',
        },
      },
    },
  },
  // ... Repeat for sizes 4 through 9 ...
  10: {
    v1: [
      {
        connectors: ['right'],
        maskImage: requireMaskSync('10/1-top-1.svg'),
        position: { top: '0', left: '0px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['left', 'right'],
        maskImage: requireMaskSync('10/1-top-2.svg'),
        position: { top: '0', left: '-80px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('10/1-top-3.svg'),
        position: { top: '0', left: '-160px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['left', 'bottom', 'right'],
        maskImage: requireMaskSync('10/blank.svg'),
        position: { top: '0', left: '-240px' },
        width: 80,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('10/1-top-5.svg'),
        position: { top: '0', left: '-320px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('10/blank.svg'),
        position: { top: '-200px', left: '0' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['top', 'right'],
        maskImage: requireMaskSync('10/1-bottom-2.svg'),
        position: { top: '-200px', left: '-80px' },
        width: 80,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('10/1-bottom-3.svg'),
        position: { top: '-200px', left: '-160px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['left'],
        maskImage: requireMaskSync('10/1-bottom-4.svg'),
        position: { top: '-200px', left: '-240px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['top'],
        maskImage: requireMaskSync('10/blank.svg'),
        position: { top: '-200px', left: '-320px' },
        width: 80,
        height: 200,
      },
    ],
    v2: [
      {
        connectors: ['bottom', 'right'],
        maskImage: requireMaskSync('10/blank.svg'),
        position: { top: '0', left: '0px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('10/2-top-2.svg'),
        position: { top: '0', left: '-80px' },
        width: 80,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('10/2-top-3.svg'),
        position: { top: '0', left: '-160px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['left', 'bottom', 'right'],
        maskImage: requireMaskSync('10/blank.svg'),
        position: { top: '0', left: '-240px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['bottom'],
        maskImage: requireMaskSync('10/2-top-5.svg'),
        position: { top: '0', left: '-320px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('10/2-bottom-1.svg'),
        position: { top: '-200px', left: '0' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('10/2-bottom-2.svg'),
        position: { top: '-200px', left: '-80px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['top', 'right'],
        maskImage: requireMaskSync('10/2-bottom-3.svg'),
        position: { top: '-200px', left: '-160px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('10/2-bottom-4.svg'),
        position: { top: '-200px', left: '-240px' },
        width: 80,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('10/2-bottom-5.svg'),
        position: { top: '-200px', left: '-320px' },
        width: 80,
        height: 200,
      },
    ],
    v3: [
      {
        connectors: ['bottom', 'right'],
        maskImage: requireMaskSync('10/blank.svg'),
        position: { top: '0', left: '0px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['bottom', 'right'],
        maskImage: requireMaskSync('10/3-top-2.svg'),
        position: { top: '0', left: '-80px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('10/3-top-3.svg'),
        position: { top: '0', left: '-160px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('10/3-top-4.svg'),
        position: { top: '0', left: '-240px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['left', 'bottom'],
        maskImage: requireMaskSync('10/3-top-5.svg'),
        position: { top: '0', left: '-320px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('10/3-bottom-1.svg'),
        position: { top: '-200px', left: '0' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['right'],
        maskImage: requireMaskSync('10/3-bottom-2.svg'),
        position: { top: '-200px', left: '-80px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['top'],
        maskImage: requireMaskSync('10/3-bottom-3.svg'),
        position: { top: '-200px', left: '-160px' },
        width: 80,
        height: 200,
      },
      {
        connectors: ['left', 'right'],
        maskImage: requireMaskSync('10/blank.svg'),
        position: { top: '-200px', left: '-240px' },
        width: 80,
        height: 200,
      },
      {
        connectors: [],
        maskImage: requireMaskSync('10/3-bottom-5.svg'),
        position: { top: '-200px', left: '-320px' },
        width: 80,
        height: 200,
      },
    ],
    styles: {
      puzzleContainer: {
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
      },
      piece: {
        width: 'calc(var(--container-size) / 5)',
        height: '200px',
      },
      connectors: {
        '.connector-left': {
          ...connectorLeft(10),
          backgroundPosition: '-46px -131px',
          top: '150px',
          left: '-41px',
        },
        '.connector-right': {
          ...connectorRight(10),
          backgroundPosition: '-90px -32px',
          top: '51px',
          right: '-41px',
        },
        '.connector-top': {
          ...connectorTop(10),
          backgroundPosition: '-116px -163px',
          top: '-18px',
          left: '23px',
        },
        '.connector-bottom': {
          ...connectorBottom(10),
          backgroundPosition: '-202px -200px',
          bottom: '-38px',
          left: '42px',
        },
      },
    },
  },
};
