// gameAudio.js
import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import * as Tone from 'tone';

export const useGameAudioStore = defineStore('gameAudio', () => {
  const isGameExited = ref(false);
  const isSpeechPlaying = ref(false);
  const musicVolume = ref(-18); // in decibels
  const effectsVolume = ref(-12); // in decibels
  const speechVolume = ref(-3); // in decibels
  const isLoading = ref(false);

  const activeAudio = {
    music: null,
    effects: [],
    speech: null,
  };

  const preloadedAudio = new Map();
  const audioQueue = [];

  const duckedMusicVolume = computed(() => {
    return isSpeechPlaying.value ? musicVolume.value - 6 : musicVolume.value;
  });

  function updateVolumes() {
    if (activeAudio.music) {
      activeAudio.music.volume.value = duckedMusicVolume.value;
    }
    activeAudio.effects.forEach((effect) => {
      effect.volume.value = effectsVolume.value;
    });
    if (activeAudio.speech) {
      activeAudio.speech.volume.value = speechVolume.value;
    }
  }

  watch(duckedMusicVolume, updateVolumes);
  watch(effectsVolume, updateVolumes);
  watch(speechVolume, updateVolumes);

  watch(isGameExited, (exited) => {
    if (exited) {
      stopAllAudio();
      garbageCollect();
    }
  });

  async function loadAudio(url) {
    if (preloadedAudio.has(url)) {
      return preloadedAudio.get(url);
    }
    isLoading.value = true;
    try {
      const buffer = await Tone.Buffer.load(url);
      preloadedAudio.set(url, buffer);
      isLoading.value = false;
      processAudioQueue();
      return buffer;
    } catch (error) {
      console.error('Error loading audio:', error);
      isLoading.value = false;
    }
  }

  function preloadAudioFiles(urls) {
    const loadPromises = urls.map((url) => loadAudio(url));
    return Promise.all(loadPromises);
  }

  function processAudioQueue() {
    while (audioQueue.length > 0) {
      const { type, url, options } = audioQueue.shift();
      switch (type) {
        case 'music':
          playMusicImmediately(url, options);
          break;
        case 'effect':
          playEffectImmediately(url);
          break;
        case 'speech':
          playSpeechImmediately(url);
          break;
      }
    }
  }

  function playMusic(
    url,
    {
      loop = true,
      loopStart = 0,
      loopEnd = null,
      fadeInDuration = 1, // in seconds
      crossFadeDuration = 2, // in seconds
    } = {}
  ) {
    if (preloadedAudio.has(url)) {
      playMusicImmediately(url, {
        loop,
        loopStart,
        loopEnd,
        fadeInDuration,
        crossFadeDuration,
      });
    } else {
      audioQueue.push({
        type: 'music',
        url,
        options: {
          loop,
          loopStart,
          loopEnd,
          fadeInDuration,
          crossFadeDuration,
        },
      });
      loadAudio(url);
    }
  }

  async function playMusicImmediately(
    url,
    {
      loop = true,
      loopStart = 0,
      loopEnd = null,
      fadeInDuration = 1, // in seconds
      crossFadeDuration = 2, // in seconds
    } = {}
  ) {
    await Tone.start(); // Ensure AudioContext is started

    // Crossfade out the current music
    if (activeAudio.music) {
      activeAudio.music.volume.linearRampToValueAtTime(
        -Infinity,
        `+${crossFadeDuration}`
      );
      setTimeout(() => {
        activeAudio.music.stop();
        activeAudio.music.dispose();
        activeAudio.music = null;
      }, crossFadeDuration * 1000);
    }

    // Load the buffer
    const buffer = preloadedAudio.get(url);
    if (!buffer) {
      console.error('Buffer not found for URL:', url);
      return;
    }

    // Create a new player
    const player = new Tone.Player(buffer, () => {
      // Player is loaded and ready
    }).toDestination();

    // Set loop points
    player.loop = loop;
    player.loopStart = loopStart;
    player.loopEnd = loopEnd !== null ? loopEnd : buffer.duration;

    // Set initial volume to silence for fade-in
    player.volume.value = -Infinity;

    // Start the player
    player.start();

    // Fade in
    player.volume.linearRampToValueAtTime(
      duckedMusicVolume.value,
      `+${fadeInDuration}`
    );

    activeAudio.music = player;
  }

  function stopMusic(fadeOutDuration = 1) {
    if (activeAudio.music) {
      activeAudio.music.volume.linearRampToValueAtTime(
        -Infinity,
        `+${fadeOutDuration}`
      );
      setTimeout(() => {
        activeAudio.music.stop();
        activeAudio.music.dispose();
        activeAudio.music = null;
      }, fadeOutDuration * 1000);
    }
  }

  function playEffect(url) {
    if (preloadedAudio.has(url)) {
      playEffectImmediately(url);
    } else {
      audioQueue.push({ type: 'effect', url });
      loadAudio(url);
    }
  }

  async function playEffectImmediately(url) {
    await Tone.start(); // Ensure AudioContext is started

    const buffer = preloadedAudio.get(url);
    if (!buffer) {
      console.error('Buffer not found for URL:', url);
      return;
    }

    const player = new Tone.Player(buffer).toDestination();
    player.volume.value = effectsVolume.value;
    player.start();
    activeAudio.effects.push(player);

    player.onstop = () => {
      activeAudio.effects = activeAudio.effects.filter((p) => p !== player);
      player.dispose();
    };
  }

  function playSpeech(url) {
    if (preloadedAudio.has(url)) {
      playSpeechImmediately(url);
    } else {
      audioQueue.push({ type: 'speech', url });
      loadAudio(url);
    }
  }

  async function playSpeechImmediately(url) {
    await Tone.start(); // Ensure AudioContext is started

    stopSpeech();
    isSpeechPlaying.value = true;

    const buffer = preloadedAudio.get(url);
    if (!buffer) {
      console.error('Buffer not found for URL:', url);
      return;
    }

    const player = new Tone.Player(buffer).toDestination();
    player.volume.value = speechVolume.value;
    player.start();
    activeAudio.speech = player;

    // Duck music volume
    if (activeAudio.music) {
      activeAudio.music.volume.value = duckedMusicVolume.value;
    }

    player.onstop = () => {
      isSpeechPlaying.value = false;
      if (activeAudio.music) {
        activeAudio.music.volume.value = musicVolume.value;
      }
      activeAudio.speech.dispose();
      activeAudio.speech = null;
    };
  }

  function stopSpeech() {
    if (activeAudio.speech) {
      activeAudio.speech.stop();
      activeAudio.speech.dispose();
      activeAudio.speech = null;
      isSpeechPlaying.value = false;

      // Restore music volume
      if (activeAudio.music) {
        activeAudio.music.volume.value = musicVolume.value;
      }
    }
  }

  function stopAllAudio() {
    stopMusic();
    activeAudio.effects.forEach((effect) => {
      effect.stop();
      effect.dispose();
    });
    activeAudio.effects = [];
    stopSpeech();
  }

  function garbageCollect() {
    preloadedAudio.clear();
    Tone.Transport.cancel();
    // Dispose of any remaining Tone.js resources
    Tone.context.close();
  }

  function setGameExited(exited) {
    isGameExited.value = exited;
  }

  return {
    playMusic,
    stopMusic,
    playEffect,
    playSpeech,
    stopSpeech,
    stopAllAudio,
    setGameExited,
    preloadAudioFiles,
    garbageCollect,
    isGameExited,
    isSpeechPlaying,
    isLoading,
    musicVolume,
    effectsVolume,
    speechVolume,
  };
});

export function useGameAudio() {
  const gameAudioStore = useGameAudioStore();

  return {
    playMusic: gameAudioStore.playMusic,
    stopMusic: gameAudioStore.stopMusic,
    playEffect: gameAudioStore.playEffect,
    playSpeech: gameAudioStore.playSpeech,
    stopSpeech: gameAudioStore.stopSpeech,
    stopAllAudio: gameAudioStore.stopAllAudio,
    setGameExited: gameAudioStore.setGameExited,
    preloadAudioFiles: gameAudioStore.preloadAudioFiles,
    garbageCollect: gameAudioStore.garbageCollect,
    isGameExited: computed(() => gameAudioStore.isGameExited),
    isSpeechPlaying: computed(() => gameAudioStore.isSpeechPlaying),
    isLoading: computed(() => gameAudioStore.isLoading),
    musicVolume: computed({
      get: () => gameAudioStore.musicVolume,
      set: (value) => {
        gameAudioStore.musicVolume = value;
        updateVolumes();
      },
    }),
    effectsVolume: computed({
      get: () => gameAudioStore.effectsVolume,
      set: (value) => {
        gameAudioStore.effectsVolume = value;
        updateVolumes();
      },
    }),
    speechVolume: computed({
      get: () => gameAudioStore.speechVolume,
      set: (value) => {
        gameAudioStore.speechVolume = value;
        updateVolumes();
      },
    }),
  };
}
