// create a usePuzzleImages composable that just returns an array of puzzle images
import { ref } from 'vue';

export function usePuzzleImages() {
  const puzzleImages = ref([
    'animals_bear.png',
    'animals_bird.png',
    'animals_butterfly.png',
    'animals_cat.png',
    'animals_dog.png',
    'animals_elephant.png',
    'animals_monkey.png',
    'animals_penguin.png',
    'dinosaurs_bronto.png',
    'dinosaurs_dimetrodon.png',
    'dinosaurs_parasauro.png',
    'dinosaurs_ptero.png',
    'dinosaurs_stego.png',
    'dinosaurs_trex.png',
    'dinosaurs_triceratops.png',
    'food_burger.png',
    'food_cake.png',
    'food_cookie.png',
    'food_ice-cream.png',
    'food_pizza.png',
    'food_sandwich.png',
    'food_spaghetti.png',
    'magic_castle.png',
    'magic_dragon.png',
    'magic_fairy.png',
    'magic_knight.png',
    'magic_mermaid.png',
    'magic_unicorn.png',
    'magic_wizard.png',
    'nature_cactus.png',
    'nature_flower.png',
    'nature_moon.png',
    'nature_rainbow.png',
    'nature_shell.png',
    'nature_sun.png',
    'nature_sunflower.png',
    'nature_volcano.png',
    'sports_baseball.png',
    'sports_basketball.png',
    'sports_bowling.png',
    'sports_football.png',
    'sports_golf.png',
    'sports_soccer.png',
    'sports_tennis.png',
    'vehicles_airplane.png',
    'vehicles_bus.png',
    'vehicles_car.png',
    'vehicles_dump-truck.png',
    'vehicles_helicopter.png',
    'vehicles_pirate-ship.png',
    'vehicles_rocket.png',
    'vehicles_truck.png',
  ]);

  return puzzleImages;
}
