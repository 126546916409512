export const useConnectorClass = () => {
  return (connector, key, allConnectors) => {
    if (!allConnectors || !allConnectors.length) {
      return '';
    }

    // Count occurrences of the same connector type up to the specified key
    let occurrenceCount = 0;
    for (let i = 0; i <= key; i++) {
      if (allConnectors[i] === connector) {
        occurrenceCount++;
      }
    }

    let connectorClassString = 'connector connector-' + connector;
    if (occurrenceCount > 1) {
      connectorClassString += occurrenceCount - 1;
    }

    return connectorClassString;
  };
};
