export const useGetConnectorGlobalIndex = (pieces) => {
  const getConnectorGlobalIndex = (pieceIndex, connectorIndex) => {
    let globalIndex = 0;
    for (let i = 0; i < pieceIndex; i++) {
      if (pieces[i] && pieces[i].connectors) {
        globalIndex += pieces[i].connectors.length;
      }
    }
    return globalIndex + connectorIndex;
  };

  return { getConnectorGlobalIndex };
};
