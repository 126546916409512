<template>
  <div class="puzzle-game">
    <div class="puzzle-pieces" @dragover.prevent @drop="onDrop('pieces')">
      <div
        v-for="(piece, index) in shuffledPieces"
        :key="piece.id"
        class="piece-wrapper"
        :class="{ hidden: isDraggingPiece(piece.id) }"
        :style="getPieceStyle(piece, 'pieces')"
      >
        <PuzzlePiece
          :pieceIndex="piece.originalIndex"
          :imageURL="imageURL"
          :puzzleVersion="puzzleVersion"
          draggable="true"
          @mousedown="onMouseDown($event, index, 'pieces')"
          @mouseover="onPieceHover(index, 'pieces')"
          @mouseleave="onPieceLeave(index, 'pieces')"
        />
      </div>
    </div>

    <div class="puzzle-board-wrapper">
      <div class="puzzle-board" @dragover.prevent>
        <div
          v-for="(_, index) in boardSlots"
          :key="index"
          class="puzzle-slot"
          :class="{
            'correct-slot': isCorrectSlot(index) && isPieceOverSlot(index),
            'incorrect-slot': isIncorrectSlot(index) && isPieceOverSlot(index),
          }"
          @dragover.prevent="onDragOver(index)"
          @dragleave="onDragLeave(index)"
          @drop="onDrop('board', index)"
        >
          <div
            v-if="boardPieces[index]"
            class="piece-wrapper"
            :class="{ hidden: isDraggingPiece(boardPieces[index].id) }"
            :style="getPieceStyle(boardPieces[index], 'board')"
          >
            <PuzzlePiece
              :pieceIndex="boardPieces[index].originalIndex"
              :imageURL="imageURL"
              :puzzleVersion="puzzleVersion"
              :draggable="!checkPuzzleSolved"
              @mousedown="onMouseDown($event, index, 'board')"
              :distractor="false"
              :totalPieces="2"
            />
          </div>
          <div
            class="hint"
            :class="{ 'hint-left': index === 0, 'hint-right': index === 1 }"
            :style="getHintStyle(index)"
          >
            <img :src="boardHints[index]" alt="Hint" />
          </div>
        </div>
      </div>
      <img
        :src="require('@/assets/2732/puzzleBoard-withLegs.webp')"
        alt="puzzleBoard"
      />
    </div>
    <div
      class="feedback-message"
      v-if="feedbackType !== ''"
      :class="{ success: feedbackType === 'success' }"
    >
      {{ feedbackMessage }}
    </div>

    <div class="arrows">
      <img
        class="arrow-source"
        :src="require('@/assets/2732/arrow-curved.webp')"
        alt="curved arrow"
      />
      <img
        class="arrow-destination"
        :src="require('@/assets/2732/arrow-curved.webp')"
        alt="curved arrow"
      />
      <!--      <img-->
      <!--        :src="require('@/assets/2732/arrow-straight.webp')"-->
      <!--        alt="straight arrow"-->
      <!--      />-->
    </div>
  </div>
</template>

<script setup>
  import { ref, nextTick, onMounted, onUnmounted, defineProps } from 'vue';
  import PuzzlePiece from './components/Puzzles/PuzzlePieces.vue';
  import { useTimeline } from '@/composables/useTimeline';
  const { setTimeline, play, pause, stop, reset, addEventType, useGameAudio } =
    useTimeline();
  const { playMusic, stopMusic, playEffect, playSpeech, stopSpeech } =
    useGameAudio();

  const props = defineProps({
    puzzleVersion: {
      type: String,
      required: true,
      validator: (value) => ['v1', 'v2', 'v3'].includes(value),
    },
  });

  const pieces = ref([]);
  const shuffledPieces = ref([]);
  const boardPieces = ref([]);
  const boardSlots = ref([]);
  const draggedPiece = ref(null);
  const dragSource = ref(null);
  const isDragging = ref(false);
  const dragOffset = ref({ x: 0, y: 0 });
  const currentHoveredSlot = ref(null);
  const feedbackMessage = ref('');
  const feedbackType = ref('');
  const draggedPiecePosition = ref({ x: 0, y: 0 });
  const boardHints = ref([
    require('@/assets/2-piece-mask-hint-left.svg'),
    require('@/assets/2-piece-mask-hint-right.svg'),
  ]);

  // eslint-disable-next-line no-undef
  const imageURL = require(`@/assets/puzzles/1200/dinosaurs_stego.png`);

  const generatePieces = () => {
    const numPieces = props.puzzleVersion === 'v1' ? 2 : 2; // Adjust based on puzzle version
    pieces.value = Array.from({ length: numPieces }, (_, index) => ({
      id: `piece-${index}`,
      originalIndex: index,
      rotation: Math.floor(Math.random() * 5 - 10), // Random rotation between -10 and 10 degrees
    }));
    shuffledPieces.value = [...pieces.value].sort(() => Math.random() - 0.5);
    boardPieces.value = Array(numPieces).fill(null);
    boardSlots.value = Array(numPieces).fill(null);
  };

  const isDraggingPiece = (id) => {
    return (
      isDragging.value && draggedPiece.value && draggedPiece.value.id === id
    );
  };

  const getPieceStyle = (piece, location) => {
    const scale = location === 'pieces' ? 1.5 : 1;
    return {
      transform: `rotate(${piece.rotation}deg) scale(${scale})`,
      transition: 'transform 0.3s ease',
    };
  };

  const onMouseDown = (event, index, source) => {
    if (checkPuzzleSolved()) return;
    const pieceElement = event.target.closest('.piece-wrapper');
    const piece =
      source === 'pieces'
        ? shuffledPieces.value[index]
        : boardPieces.value[index];
    const rect = pieceElement.getBoundingClientRect();

    draggedPiece.value = {
      ...piece,
      x: event.clientX,
      y: event.clientY,
      width: rect.width,
      height: rect.height,
    };
    dragSource.value = source;
    isDragging.value = true;
    dragOffset.value = {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };

    const clonedPiece = pieceElement.cloneNode(true);
    clonedPiece.style.position = 'fixed';
    clonedPiece.style.left = `${event.clientX - dragOffset.value.x}px`;
    clonedPiece.style.top = `${event.clientY - dragOffset.value.y}px`;
    clonedPiece.style.width = `${rect.width / 0.75}px`;
    clonedPiece.style.height = `${rect.height / 0.75}px`;
    clonedPiece.style.transform = 'rotate(0deg) scale(1)';
    clonedPiece.style.pointerEvents = 'none';
    clonedPiece.style.zIndex = '1000';
    document.body.appendChild(clonedPiece);

    draggedPiece.value.clonedElement = clonedPiece;

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    event.preventDefault();
  };

  const onMouseMove = (event) => {
    if (isDragging.value && draggedPiece.value.clonedElement) {
      const x = event.clientX - dragOffset.value.x;
      const y = event.clientY - dragOffset.value.y;
      draggedPiece.value.clonedElement.style.left = `${x}px`;
      draggedPiece.value.clonedElement.style.top = `${y}px`;
      draggedPiecePosition.value = { x, y };

      const slots = document.querySelectorAll('.puzzle-slot');
      let hoveredSlot = null;
      slots.forEach((slot, index) => {
        const rect = slot.getBoundingClientRect();
        if (
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom
        ) {
          hoveredSlot = index;
        }
      });
      currentHoveredSlot.value = hoveredSlot;

      if (isCorrectSlot(hoveredSlot) && isPieceOverSlot(hoveredSlot)) {
        draggedPiece.value.clonedElement.style.transform =
          'rotate(0deg) scale(1)';
      }
    }
  };

  const onMouseUp = (event) => {
    if (isDragging.value) {
      const dropTarget = event.target.closest('.puzzle-slot, .puzzle-pieces');
      if (dropTarget) {
        if (dropTarget.classList.contains('puzzle-pieces')) {
          onDrop('pieces');
        } else {
          const slotIndex = Array.from(dropTarget.parentNode.children).indexOf(
            dropTarget
          );
          onDrop('board', slotIndex);
        }
      }
    }
    if (draggedPiece.value && draggedPiece.value.clonedElement) {
      document.body.removeChild(draggedPiece.value.clonedElement);
    }
    isDragging.value = false;
    draggedPiece.value = null;
    dragSource.value = null;
    currentHoveredSlot.value = null;
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  const isCorrectSlot = (index) => {
    return draggedPiece.value && draggedPiece.value.originalIndex === index;
  };

  const isIncorrectSlot = (index) => {
    return currentHoveredSlot.value === index && !isCorrectSlot(index);
  };

  const isPieceOverSlot = (index) => {
    if (!draggedPiece.value || currentHoveredSlot.value !== index) return false;

    const slots = document.querySelectorAll('.puzzle-slot');
    const slotRect = slots[index].getBoundingClientRect();
    const pieceRect = {
      left: draggedPiecePosition.value.x,
      top: draggedPiecePosition.value.y,
      right: draggedPiecePosition.value.x + draggedPiece.value.width,
      bottom: draggedPiecePosition.value.y + draggedPiece.value.height,
    };

    const overlapThreshold = 0.3;
    const overlapX = Math.max(
      0,
      Math.min(pieceRect.right, slotRect.right) -
        Math.max(pieceRect.left, slotRect.left)
    );
    const overlapY = Math.max(
      0,
      Math.min(pieceRect.bottom, slotRect.bottom) -
        Math.max(pieceRect.top, slotRect.top)
    );
    const overlapArea = overlapX * overlapY;
    const pieceArea = draggedPiece.value.width * draggedPiece.value.height;

    return overlapArea / pieceArea > overlapThreshold;
  };

  const onDragOver = (index) => {
    currentHoveredSlot.value = index;
  };

  const onDragLeave = () => {
    currentHoveredSlot.value = null;
  };

  const onDrop = (target, index = null) => {
    if (!draggedPiece.value) return;

    if (target === 'pieces') {
      if (dragSource.value === 'board') {
        const boardIndex = boardPieces.value.findIndex(
          (piece) => piece && piece.id === draggedPiece.value.id
        );
        boardPieces.value[boardIndex] = null;
        shuffledPieces.value.push(draggedPiece.value);
      }
      feedbackMessage.value = '';
      feedbackType.value = '';
    } else if (target === 'board' && index !== null) {
      // answer is correct
      if (isCorrectSlot(index) && isPieceOverSlot(index)) {
        playEffect(require('@/assets/audio/effect-drop.mp3'));
        playSpeech(require('@/assets/audio/speech-3-great-job.mp3'));

        if (dragSource.value === 'pieces') {
          const pieceIndex = shuffledPieces.value.findIndex(
            (piece) => piece.id === draggedPiece.value.id
          );
          shuffledPieces.value.splice(pieceIndex, 1);
        } else if (dragSource.value === 'board') {
          const oldIndex = boardPieces.value.findIndex(
            (piece) => piece && piece.id === draggedPiece.value.id
          );
          boardPieces.value[oldIndex] = null;
        }
        boardPieces.value[index] = { ...draggedPiece.value, rotation: 0 };

        nextTick(() => {
          if (checkPuzzleSolved()) {
            playSpeech(
              require('@/assets/audio/speech-4-you-solved-the-puzzle.mp3')
            );

            playEffect(require('@/assets/audio/effect-correct.mp3'));
            // feedbackMessage.value = 'Congratulations! You solved the puzzle!';
            // feedbackType.value = 'success';
          } else {
            feedbackMessage.value = '';
            feedbackType.value = '';
          }
        });
      } else {
        // answer is incorrect
        playSpeech(require('@/assets/audio/speech-5-thats-not-quite.mp3'));
      }

      setTimeout(() => {
        feedbackType.value = '';
      }, 1500);
    }
  };

  const onPieceHover = (index, source) => {
    if (source === 'pieces') {
      shuffledPieces.value[index].rotation = 0;
    }
  };

  const onPieceLeave = (index, source) => {
    if (source === 'pieces' && !isDragging.value) {
      shuffledPieces.value[index].rotation = Math.floor(
        Math.random() * 20 - 10
      );
    }
  };

  const checkPuzzleSolved = () => {
    return boardPieces.value.every(
      (piece, index) => piece && piece.originalIndex === index
    );
  };

  const getHintStyle = (index) => {
    const isCorrect = isCorrectSlot(index) && isPieceOverSlot(index);
    const isIncorrect = isIncorrectSlot(index) && isPieceOverSlot(index);
    const isPieceInPlace = boardPieces.value[index] !== null;

    let filter = 'none';
    if (isCorrect) {
      filter = `
      drop-shadow(1px 0 0 #4CAF50) drop-shadow(-1px 0 0 #4CAF50)
      drop-shadow(0 1px 0 #4CAF50) drop-shadow(0 -1px 0 #4CAF50)
      drop-shadow(1px 1px 0 #4CAF50) drop-shadow(-1px -1px 0 #4CAF50)
      drop-shadow(1px -1px 0 #4CAF50) drop-shadow(-1px 1px 0 #4CAF50)
    `;
    } else if (isIncorrect) {
      filter = `
      drop-shadow(1px 0 0 #F44336) drop-shadow(-1px 0 0 #F44336)
      drop-shadow(0 1px 0 #F44336) drop-shadow(0 -1px 0 #F44336)
      drop-shadow(1px 1px 0 #F44336) drop-shadow(-1px -1px 0 #F44336)
      drop-shadow(1px -1px 0 #F44336) drop-shadow(-1px 1px 0 #F44336)
    `;
    }

    return {
      opacity: isPieceInPlace ? 0 : 1,
      filter: filter,
      transition: 'opacity 0.3s ease, filter 0.3s ease',
    };
  };

  onMounted(() => {
    generatePieces();
    setTimeline({
      0.5: {
        type: 'playSpeech',
        url: require('@/assets/audio/speech-1-lets-play.mp3'),
      },
      0.66: {
        type: 'playMusic',
        url: require('@/assets/audio/fpo-music.mp3'),
      },
      0.7: {
        type: 'animate',
        target: '.puzzle-pieces',
        props: {
          top: '25vh',
          duration: 0.5,
          ease: 'power2.out',
        },
      },

      1.2: {
        type: 'animate',
        target: '.puzzle-board-wrapper',
        props: {
          top: '25vh',
          duration: 1.3,
          ease: 'power4.out',
        },
      },
      1.6: {
        type: 'animate',
        target: '.puzzle-board',
        props: {
          top: '25vh',
          duration: 1,
          ease: 'power2.out',
        },
      },

      1.9: {
        type: 'wait',
      },

      3: {
        type: 'playSpeech',
        url: require('@/assets/audio/speech-2-move-the-pieces.mp3'),
      },
      4.1: {
        type: 'animate',
        target: '.arrow-source',
        props: {
          top: '20vh',
          duration: 1,
          ease: 'power4.out',
        },
      },
      4.8: {
        type: 'animate',
        target: '.arrow-source',
        props: {
          x: '+=5',
          repeat: 5,
          yoyo: true,
          duration: 0.1,
          ease: 'power1.inOut',
        },
      },

      5.7: {
        type: 'animate',
        target: '.arrow-destination',
        props: {
          top: '40vh',
          duration: 1,
          ease: 'power4.out',
        },
      },
      6.4: {
        type: 'animate',
        target: '.arrow-destination',
        props: {
          x: '+=5',
          repeat: 5,
          yoyo: true,
          duration: 0.1,
          ease: 'power1.inOut',
        },
      },
    });

    play();
  });

  onUnmounted(() => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  });
</script>
<style lang="scss" scoped>
  @import '@/styles/drag-and-drop-game.scss';
</style>
