<template>
  <div
    class="train-car"
    :style="getCarStyle(car, index)"
    :class="getCarClass(car)"
  >
    <img
      v-if="!car.isEngine"
      :src="require('@/assets/2732/' + car.trainCarImage)"
      alt="train car type 1"
      class="train-image"
    />
    <div v-else class="train-engine train-engine-container">
      <img
        :src="require('@/assets/2732/trainEngine-base-no-wheels.webp')"
        alt="train engine"
        class="train-image"
      />
      <div class="rear-wheel" :style="wheelStyle"></div>
      <div class="rear-wheel-2" :style="wheelStyle"></div>
    </div>
    <div
      class="doors"
      :class="{
        tankerDoor: car.trainCarImage === 'traincar-3-base.webp',
      }"
    >
      <div class="door-mask">
        <img
          v-if="car.trainCarImage === 'traincar-1-base.webp'"
          :src="require('@/assets/2732/traincar-1-doorLeft.webp')"
          alt="Door Left"
          class="door-left"
          :class="{ closed: car.isClosed === true && !doorsOpen }"
        />
        <img
          v-if="car.trainCarImage === 'traincar-1-base.webp'"
          :src="require('@/assets/2732/traincar-1-doorRight.webp')"
          alt="Door Right"
          class="door-right"
          :class="{ closed: car.isClosed === true && !doorsOpen }"
        />
        <img
          v-if="car.trainCarImage === 'traincar-2-base.webp'"
          :src="require('@/assets/2732/traincar-2-doorLeft.webp')"
          alt="Door Left"
          class="door-left"
          :class="{ closed: car.isClosed === true && !doorsOpen }"
        />
        <img
          v-if="car.trainCarImage === 'traincar-2-base.webp'"
          :src="require('@/assets/2732/traincar-2-doorRight.webp')"
          alt="Door Right"
          class="door-right"
          :class="{ closed: car.isClosed === true && !doorsOpen }"
        />
        <img
          v-if="
            car.trainCarImage === 'traincar-3-base.webp' &&
            !car.isEngine
          "
          :src="require('@/assets/2732/traincar-3-door.webp')"
          alt="Door"
          class="door"
          :class="{ closed: car.isClosed === true && !doorsOpen }"
        />
      </div>
      <div class="puzzle-piece-resize-wrapper">
        <PuzzlePiece
          v-if="
            car.puzzlePieceComponent &&
            car.puzzlePieceIndex !== null &&
            !car.isEngine &&
            !car.isDistractor
          "
          :key="'puzzlePiece_' + car.puzzlePieceIndex"
          :distractor="false"
          :pieceIndex="car.puzzlePieceIndex"
          :imageURL="imageURL"
          :puzzleVersion="puzzleVersion"
          :totalPieces="numberOfPieces"
        />
        <PuzzlePiece
          v-else-if="!car.isEngine && car.isDistractor"
          :key="'distractor_' + car.puzzlePieceIndex"
          :distractor="true"
          :pieceIndex="car.puzzlePieceIndex"
          :imageURL="imageURL"
          :puzzleVersion="distractorVersion"
          :totalPieces="numberOfPieces"
        />
      </div>
    </div>

    <div
      class="car-connector"
      v-if="index > 0"
      :style="{
        width: `${car.connectorWidth}px`,
        left: `${car.connectorLeft}px`,
      }"
    ></div>
  </div>
</template>

<script>
  import PuzzlePiece from '@/components/Puzzles/PuzzlePieces.vue';

  export default {
    name: 'TrainCar',
    components: {
      PuzzlePiece,
    },
    props: {
      car: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
      doorsOpen: {
        type: Boolean,
        required: true,
      },
      imageURL: {
        type: String,
        required: true,
      },
      puzzleVersion: {
        type: String,
        required: true,
      },
      distractorVersion: {
        type: String,
        required: true,
      },
      numberOfPieces: {
        type: Number,
        required: true,
      },
      wheelStyle: {
        type: Object,
        required: true,
      },
      getCarStyle: {
        type: Function,
        required: true,
      },
      getCarClass: {
        type: Function,
        required: true,
      },
    },
  };
</script>