import { computed } from 'vue';
import { usePieceStyle } from '@/composables/usePieceStyle';
import { useCurrentPiece } from '@/composables/useCurrentPiece';
import { useConnectorClass } from '@/composables/useConnectorClass';
import { useGetConnectorGlobalIndex } from '@/composables/useGetConnectorGlobalIndex';
import { useAllConnectors } from '@/composables/useAllConnectors';
import { useImageVariable } from '@/composables/useImageVariable';

export function usePuzzlePiece(props, pieces) {
  const { currentPiece } = useCurrentPiece(props, pieces);
  const { pieceStyle } = usePieceStyle(currentPiece, props);
  const connectorClass = useConnectorClass();
  const { getConnectorGlobalIndex } = useGetConnectorGlobalIndex(pieces);
  const { allConnectors } = useAllConnectors(pieces);
  const { imageVariable } = useImageVariable(props);

  // Return the state and methods
  return {
    currentPiece: computed(() => currentPiece.value),
    pieceStyle,
    connectorClass,
    getConnectorGlobalIndex,
    allConnectors,
    imageVariable,
  };
}
