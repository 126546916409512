<template>
  <div class="container">
    <div class="clouds"></div>
    <div class="overlay"></div>
    <div class="wrapper">
      <div class="tree" ref="treeLeft" id="tree-left">
        <img :src="require('@/assets/2732/tree-1.webp')" alt="left tree" />
      </div>
      <div class="tree" ref="treeRight" id="tree-right">
        <img :src="require('@/assets/2732/tree-2.webp')" alt="right tree" />
      </div>

      <button
        @click="switchGameStep"
        style="position: absolute; bottom: 0; left: 0; z-index: 50000"
      >
        Toggle Game Step
      </button>
      <transition :name="transitionName">
        <component :is="gameStep" :key="gameStep" class="game-step" />
      </transition>
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted, watch } from 'vue';
  import Play from './Play.vue';
  import DragMiniGame from './DragMiniGame.vue';
  import Introduction from './Introduction.vue';
  import { useGSAPAnimations } from '@/composables/useGSAPAnimations';

  export default {
    components: {
      Introduction,
      DragMiniGame,
      Play,
    },
    setup() {
      const gameStep = ref('Introduction');
      const gameSteps = ['Introduction', 'DragMiniGame']; //, 'Play'
      const treeLeft = ref(null);
      const treeRight = ref(null);
      const treesAreVisible = ref(false);

      const direction = ref('forward');

      const transitionName = computed(() => 'slide');

      const switchGameStep = () => {
        const currentIndex = gameSteps.indexOf(gameStep.value);
        const nextIndex = (currentIndex + 1) % gameSteps.length;

        direction.value =
          nextIndex > currentIndex ||
          (currentIndex === gameSteps.length - 1 && nextIndex === 0)
            ? 'forward'
            : 'backward';

        gameStep.value = gameSteps[nextIndex];
      };

      const { animate } = useGSAPAnimations();

      const animateTrees = () => {
        console.log('Animating trees');
        animate(treeLeft.value, {
          y: 0,
          opacity: 1,
          duration: 1.2,
          ease: 'power2.out',
        });
        animate(treeRight.value, {
          y: 0,
          opacity: 1,
          duration: 1.2,
          ease: 'power2.out',
          delay: 0.4, // Slight delay for a staggered effect
        });
      };

      onMounted(async () => {
        // Animate the trees
        if (gameStep.value !== 'Introduction') {
          animateTrees();
        }
      });

      watch(gameStep, (newValue, oldValue) => {
        if (newValue === 'Introduction') {
          treesAreVisible.value = false;
        } else {
          if (!treesAreVisible.value) {
            animateTrees(); // Reset trees if game step changes to 'Play'
          }
          treesAreVisible.value = true;
        }
      });

      return {
        gameStep,
        switchGameStep,
        transitionName,
        direction,
        treeLeft,
        treeRight,
      };
    },
  };
</script>

<style>
  body {
    overflow: hidden;
    padding: 0;
    margin: 0;
  }

  h1 {
    width: 100%;
    text-align: center;
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
  }

  * {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
  }

  .debug-controls {
    position: absolute;
    top: 0;
    left: 5px;
    z-index: 20000;
    width: 100vw;
    display: flex;
    justify-content: space-around;
  }

  button {
    padding: 20px;
  }

  img {
    pointer-events: none;
  }

  .hide {
    display: none;
  }

  .pieces {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .pieces .puzzle-piece {
    /* position: static; */
  }

  .game-step {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.5s ease-in-out;
  }

  .slide-enter-from {
    transform: translateX(100%);
  }

  .slide-leave-to {
    transform: translateX(-100%);
  }

  .slide-enter-to,
  .slide-leave-from {
    transform: translateX(0);
  }

  /* Reverse animation styles */
  .slide-reverse-enter-from {
    transform: translateX(-100%);
  }

  .slide-reverse-leave-to {
    transform: translateX(100%);
  }
</style>
<style src="./styles/puzzle-train.scss" lang="scss"></style>
