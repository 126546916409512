// connectorStyles.js

const getAssetUrl = (puzzle, type) => {
  return require(`@/assets/masks/${puzzle}/connector-${type}.svg`);
};

export const connectorTop = (puzzle = 4) => ({
  left: '64px',
  top: '-40px',
  WebkitMaskImage: `url(${getAssetUrl(puzzle, 'top')})`,
  maskImage: `url(${getAssetUrl(puzzle, 'top')})`,
});

export const connectorRight = (puzzle = 4) => ({
  right: '-86.2px',
  top: 'calc(51% + 0.3px)',
  WebkitMaskImage: `url(${getAssetUrl(puzzle, 'right')})`,
  maskImage: `url(${getAssetUrl(puzzle, 'right')})`,
});

export const connectorRight45 = (puzzle = 4) => ({
  right: '-86.2px',
  top: 'calc(51% + 0.3px)',
  WebkitMaskImage: `url(${getAssetUrl(puzzle, '45-right')})`,
  maskImage: `url(${getAssetUrl(puzzle, '45-right')})`,
});

export const connectorRight45Down = (puzzle = 4) => ({
  right: '-86.2px',
  top: 'calc(51% + 0.3px)',
  WebkitMaskImage: `url(${getAssetUrl(puzzle, '45-right-down')})`,
  maskImage: `url(${getAssetUrl(puzzle, '45-right-down')})`,
});

export const connectorBottom = (puzzle = 4) => ({
  WebkitMaskImage: `url(${getAssetUrl(puzzle, 'bottom')})`,
  maskImage: `url(${getAssetUrl(puzzle, 'bottom')})`,
  left: 'calc(50% + 0.3px)',
  transform: 'translateX(-50%)',
  bottom: '-80px',
});

export const connectorLeft = (puzzle = 4) => ({
  left: 'calc(-1.04 * 80px + 3px)',
  top: 'calc(50%)',
  WebkitMaskImage: `url(${getAssetUrl(puzzle, 'left')})`,
  maskImage: `url(${getAssetUrl(puzzle, 'left')})`,
});

export const connectorLeft45 = (puzzle = 4) => ({
  left: 'calc(-1.04 * 80px + 3px)',
  top: 'calc(50%)',
  WebkitMaskImage: `url(${getAssetUrl(puzzle, '45-left')})`,
  maskImage: `url(${getAssetUrl(puzzle, '45-left')})`,
});

export const connectorLeft45Down = (puzzle = 4) => ({
  left: 'calc(-1.04 * 80px + 3px)',
  top: 'calc(50%)',
  WebkitMaskImage: `url(${getAssetUrl(puzzle, '45-left-down')})`,
  maskImage: `url(${getAssetUrl(puzzle, '45-left-down')})`,
});
