import { createApp } from 'vue';
import { createPinia } from 'pinia';

import Game from './Game.vue';

const app = createApp(Game);
const pinia = createPinia();

app.use(pinia);

app.mount('#app');
