import { ref, onMounted, onUnmounted } from 'vue';
import { gsap, TimelineLite, Back, Expo, Bounce, Elastic, Power4 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import TypingAnimation from './TypingAnimation'; // Replace with the correct path

gsap.registerPlugin(TimelineLite, Expo, Bounce, Elastic, Power4, ScrollTrigger);

/**
 * @typedef {Object} GSAPAnimationOptions
 * @property {number} [defaultDuration=0.4] - Default duration for animations
 * @property {string} [defaultEase='expo.inOut'] - Default easing function for animations
 * @property {boolean} [scrollTrigger=false] - Whether to use ScrollTrigger plugin
 */

/**
 * A composable for GSAP animations
 * @param {GSAPAnimationOptions} options - Configuration options for the animations
 * @returns {Object} An object containing animation functions and utilities
 */
export function useGSAPAnimations(options = {}) {
  const {
    defaultDuration = 0.4,
    defaultEase = 'expo.inOut',
    scrollTrigger = false,
  } = options;

  const timeline = gsap.timeline();
  const container = ref(null);

  const animations = ref([]);
  const tweens = { Expo, Bounce, Elastic, Power4 };

  const typingAnimation = null; //TODO: add this in //new TypingAnimation();

  /**
   * Helper function to create and store animations
   * @param {string|Element|Array} target - The target(s) to animate
   * @param {Object} props - The properties to animate
   * @param {Object} [options={}] - Additional options for the animation
   * @returns {gsap.core.Tween} The created GSAP tween
   */
  const createAnimation = (target, props, options = {}) => {
    const anim = gsap.to(target, { ...props, ...options });
    animations.value.push(anim);
    return anim;
  };

  /**
   * Generic animation function
   * @param {string|Element|Array} target - The target(s) to animate
   * @param {Object} props - The properties to animate
   * @param {Object} [options={}] - Additional options for the animation
   * @returns {gsap.core.Tween} The created GSAP tween
   * @example
   * animate('#myElement', {
   *   x: 100,
   *   opacity: 1,
   *   duration: 1,
   *   ease: 'power2.out'
   * });
   */
  const animate = (target, props, options = {}) => {
    return createAnimation(
      target,
      {
        duration: defaultDuration,
        ease: defaultEase,
        ...props,
      },
      options
    );
  };

  /**
   * Spins and fades out an object
   * @param {string|Element} object - The target to animate
   * @param {number} [duration=defaultDuration] - Animation duration
   * @returns {gsap.core.Tween} The created GSAP tween
   * @example
   * spinAndFade('#myElement', 0.5);
   */
  const spinAndFade = (object, duration = defaultDuration) => {
    return animate(object, {
      opacity: 0,
      rotation: 360,
      scale: 0,
      duration: duration,
    });
  };

  /**
   * Reverses the spin and fade animation
   * @param {string|Element} object - The target to animate
   * @param {number} [duration=defaultDuration] - Animation duration
   * @returns {gsap.core.Tween} The created GSAP tween
   * @example
   * reverseSpinAndFade('#myElement', 0.5);
   */
  const reverseSpinAndFade = (object, duration = defaultDuration) => {
    return animate(object, {
      opacity: 1,
      rotation: 0,
      scale: 1,
      duration: duration,
    });
  };

  /**
   * Creates a swipe animation in the specified direction
   * @param {string} direction - The direction of the swipe ('x' or 'y')
   * @param {number} [distance=3000] - The distance to swipe
   * @param {number} [duration=1.8] - Animation duration
   * @returns {gsap.core.Tween} The created GSAP tween
   * @example
   * swipeAnimation('x', 1000, 1);
   */
  const swipeAnimation = (direction, distance = 3000, duration = 1.8) => {
    const props = {
      [direction]: distance,
      duration: duration,
      ease: 'power4.inOut',
    };
    return animate(container.value, props);
  };

  /**
   * Animates the board to swipe left
   * @returns {gsap.core.Tween} The created GSAP tween
   * @example
   * animateBoardSwipeLeft();
   */
  const animateBoardSwipeLeft = () => swipeAnimation('x', -3000);

  /**
   * Animates the board to swipe right
   * @returns {gsap.core.Tween} The created GSAP tween
   * @example
   * animateBoardSwipeRight();
   */
  const animateBoardSwipeRight = () => swipeAnimation('x', 3000);

  /**
   * Animates the board to swipe up
   * @returns {gsap.core.Tween} The created GSAP tween
   * @example
   * animateBoardSwipeUp();
   */
  const animateBoardSwipeUp = () => swipeAnimation('y', -3000);

  /**
   * Animates the board to swipe down
   * @returns {gsap.core.Tween} The created GSAP tween
   * @example
   * animateBoardSwipeDown();
   */
  const animateBoardSwipeDown = () => swipeAnimation('y', 3000);

  /**
   * Animates the board to swipe in from the left
   * @returns {gsap.core.Tween} The created GSAP tween
   * @example
   * animateBoardSwipeIn();
   */
  const animateBoardSwipeIn = () => {
    return animate(container.value, {
      x: 0,
      duration: 1.8,
      ease: 'power4.inOut',
    });
  };

  /**
   * Creates a scroll-triggered animation
   * @param {string|Element} target - The target to animate
   * @param {Object} props - The properties to animate
   * @param {Object} [triggerOptions={}] - ScrollTrigger options
   * @returns {gsap.core.Tween} The created GSAP tween
   * @example
   * createScrollAnimation('.myElement', {
   *   opacity: 0,
   *   y: 50
   * }, {
   *   start: 'top center',
   *   end: 'bottom center',
   *   scrub: true
   * });
   */
  const createScrollAnimation = (target, props, triggerOptions = {}) => {
    if (!scrollTrigger) {
      console.warn('ScrollTrigger is not enabled. Enable it in the options.');
      return;
    }

    return gsap.to(target, {
      ...props,
      scrollTrigger: {
        trigger: target,
        ...triggerOptions,
      },
    });
  };

  /**
   * Creates a staggered animation for multiple targets
   * @param {string|Element|Array} targets - The targets to animate
   * @param {Object} props - The properties to animate
   * @param {Object} [staggerOptions={}] - Stagger options
   * @returns {gsap.core.Timeline} The created GSAP timeline
   * @example
   * staggerAnimation('.item', {
   *   opacity: 1,
   *   y: 0
   * }, {
   *   amount: 0.5,
   *   from: 'start'
   * });
   */
  const staggerAnimation = (targets, props, staggerOptions = {}) => {
    return gsap.to(targets, {
      ...props,
      stagger: staggerOptions,
    });
  };

  /**
   * Reveals text with a fade-in animation
   * @param {string|Element} target - The target to animate
   * @param {number} [duration=1] - Animation duration
   * @returns {gsap.core.Tween} The created GSAP tween
   * @example
   * revealText('#myText', 0.5);
   */
  const revealText = (target, duration = 1) => {
    return animate(target, {
      opacity: 1,
      y: 0,
      duration: duration,
      ease: 'power2.out',
    });
  };

  onMounted(() => {
    if (scrollTrigger) {
      ScrollTrigger.refresh();
    }
  });

  onUnmounted(() => {
    animations.value.forEach((anim) => anim.kill());
    if (scrollTrigger) {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    }
  });

  return {
    animate,
    spinAndFade,
    reverseSpinAndFade,
    swipeAnimation,
    animateBoardSwipeLeft,
    animateBoardSwipeRight,
    animateBoardSwipeUp,
    animateBoardSwipeDown,
    animateBoardSwipeIn,
    createScrollAnimation,
    staggerAnimation,
    revealText,
    timeline,
    container,
    tweens,
    typingAnimation,
  };
}
