<template>
  <div class="introduction">
    <img
      :src="require('@/assets/splash/2732/splash_screen.png')"
      alt="Puzzle Train"
    />
  </div>
</template>

<script setup></script>

<style scoped lang="scss">
  .introduction {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  img {
    position: relative;
    display: block;
    max-width: 70%;
    height: auto;
    margin: 0 auto;
    border-radius: 10px;
    z-index: 99999;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
</style>
