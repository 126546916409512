/**
 * @file useTimeline.js
 * @description A composable function for managing a timeline of events in a Vue 3 application,
 * supporting both synchronous and asynchronous events.
 */

import { ref, onUnmounted } from 'vue';
import { useGSAPAnimations } from './useGSAPAnimations';
import { useGameAudio } from './useGameStore';

/**
 * @typedef {Object} TimelineEvent
 * @property {string} type - The type of the event.
 * @property {boolean} [async=false] - Whether the event should be handled asynchronously.
 * @property {*} [data] - Additional data for the event.
 */

/**
 * @typedef {Object.<number, TimelineEvent>} Timeline
 */

/**
 * A composable for managing a timeline of events.
 * @returns {Object} An object containing timeline control functions.
 */
export function useTimeline() {
  const {
    animate,
    spinAndFade,
    reverseSpinAndFade,
    animateBoardSwipeLeft,
    animateBoardSwipeRight,
    animateBoardSwipeUp,
    animateBoardSwipeDown,
    animateBoardSwipeIn,
    animatePuzzlePiece,
    animateAllPuzzlePieces,
    createScrollAnimation,
    staggerAnimation,
    revealText,
  } = useGSAPAnimations();

  const { playMusic, stopMusic, playEffect, playSpeech, stopSpeech } =
    useGameAudio();

  const timeline = ref(/** @type {Timeline} */ ({}));
  let currentTime = 0;
  let isPlaying = false;
  let isPaused = false;
  let timeoutId = null;

  /**
   * @type {Object.<string, function(TimelineEvent): (void|Promise<void>)>}
   */
  const eventHandlers = {
    animate: (event) => animate(event.target, event.props, event.options),
    spinAndFade: (event) => spinAndFade(event.object, event.duration),
    reverseSpinAndFade: (event) =>
      reverseSpinAndFade(event.object, event.duration),
    swipeLeft: () => animateBoardSwipeLeft(),
    swipeRight: () => animateBoardSwipeRight(),
    swipeUp: () => animateBoardSwipeUp(),
    swipeDown: () => animateBoardSwipeDown(),
    swipeIn: () => animateBoardSwipeIn(),
    animatePuzzlePiece: (event) =>
      animatePuzzlePiece(
        event.element,
        event.options,
        event.index,
        event.totalPieces
      ),
    animateAllPuzzlePieces: (event) =>
      animateAllPuzzlePieces(event.elements, event.positions),
    scrollAnimation: (event) =>
      createScrollAnimation(event.target, event.props, event.triggerOptions),
    staggerAnimation: (event) =>
      staggerAnimation(event.targets, event.props, event.staggerOptions),
    revealText: (event) => revealText(event.target, event.duration),
    playMusic: (event) => playMusic(event.url, event.options),
    stopMusic: (event) => stopMusic(event.fadeOutDuration),
    playEffect: (event) => playEffect(event.url),
    playSpeech: (event) => playSpeech(event.url),
    stopSpeech: () => stopSpeech(),
    wait: (event) =>
      new Promise((resolve) => setTimeout(resolve, event.duration * 1000)),
  };

  /**
   * Sets a new timeline of events.
   * @param {Timeline} newTimeline - The new timeline to set.
   */
  const setTimeline = (newTimeline) => {
    timeline.value = Object.entries(newTimeline).reduce(
      (acc, [time, event]) => {
        acc[Number(time)] = event;
        return acc;
      },
      /** @type {Timeline} */ ({})
    );
  };

  /**
   * Starts or resumes playing the timeline.
   */
  const play = () => {
    if (isPlaying && !isPaused) return;
    isPlaying = true;
    isPaused = false;
    scheduleNextEvent();
  };

  /**
   * Pauses the timeline.
   */
  const pause = () => {
    if (!isPlaying || isPaused) return;
    isPaused = true;
    clearTimeout(timeoutId);
  };

  /**
   * Stops the timeline and resets the current time to 0.
   */
  const stop = () => {
    isPlaying = false;
    isPaused = false;
    currentTime = 0;
    clearTimeout(timeoutId);
  };

  /**
   * Stops the timeline and clears all events.
   */
  const reset = () => {
    stop();
    timeline.value = {};
  };

  /**
   * Schedules the next event in the timeline.
   */
  const scheduleNextEvent = () => {
    if (!isPlaying || isPaused) return;

    const nextEventTime = Object.keys(timeline.value)
      .map(Number)
      .filter((time) => time > currentTime)
      .sort((a, b) => a - b)[0];

    if (nextEventTime === undefined) {
      stop();
      return;
    }

    const delay = (nextEventTime - currentTime) * 1000;
    timeoutId = setTimeout(async () => {
      currentTime = nextEventTime;
      await executeEvent(timeline.value[nextEventTime]);
      scheduleNextEvent();
    }, delay);
  };

  /**
   * Executes a single event.
   * @param {TimelineEvent} event - The event to execute.
   * @returns {Promise<void>}
   */
  const executeEvent = async (event) => {
    const handler = eventHandlers[event.type];
    if (handler) {
      if (event.async) {
        await handler(event);
      } else {
        handler(event);
      }
    } else {
      console.warn(`Unknown event type: ${event.type}`);
    }
  };

  /**
   * Adds a new event type with a custom handler.
   * @param {string} type - The type of the new event.
   * @param {function(TimelineEvent): (void|Promise<void>)} handler - The handler function for the new event type.
   */
  const addEventType = (type, handler) => {
    eventHandlers[type] = handler;
  };

  // Clean up when the component is unmounted
  onUnmounted(() => {
    stop();
  });

  return {
    setTimeline,
    play,
    pause,
    stop,
    reset,
    addEventType,
    useGameAudio,
  };
}
