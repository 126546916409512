import { computed } from 'vue';

export const useCurrentPiece = (props, pieces) => {
  const currentPiece = computed(() => {
    if (props.pieceIndex < 0 || props.pieceIndex >= pieces.length) {
      console.warn(
        `Invalid pieceIndex: ${props.pieceIndex}. Using default piece.`
      );
      return pieces[0]; // Return the first piece as a default
    }

    return pieces[props.pieceIndex];
  });

  return { currentPiece };
};
