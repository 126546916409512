import { ref, nextTick } from 'vue';
import puzzleConfigs from '@/config/puzzleConfigs.js';
import { useGSAPAnimations } from './useGSAPAnimations';

export function usePuzzlePieceAnimator(puzzlePieces, puzzleVersion) {
  const { animatePuzzlePiece } = useGSAPAnimations();

  const animatePuzzlePieces = async (puzzleType, version) => {
    await nextTick();

    const filteredPieces = puzzlePieces.value.filter(
      (piece) =>
        !piece.querySelector('.puzzle-piece').classList.contains('distractor')
    );

    if (!Array.isArray(filteredPieces) || filteredPieces.length === 0) {
      console.error('No non-distractor puzzle pieces found to animate.');
      return;
    }

    const gridConfig = puzzleConfigs[puzzleType][version];

    if (!gridConfig) {
      console.error('Grid config is undefined for version:', version);
      return;
    }

    const puzzleBoard = document.querySelector('.puzzle-board .pieces');
    const puzzleBoardRect = puzzleBoard.getBoundingClientRect();

    filteredPieces.forEach((pieceContainer, index) => {
      const targetPosition = gridConfig[index]?.position;
      if (!targetPosition) {
        console.error(`Grid config not found for piece index: ${index}`);
        return;
      }

      const wrapper = document.createElement('div');
      wrapper.style.position = 'absolute';
      wrapper.classList.add('puzzle-piece-end-of-trial-wrapper');
      wrapper.style.zIndex = 1000;
      puzzleBoard.appendChild(wrapper);

      const clonedContainer = pieceContainer.cloneNode(true);
      wrapper.appendChild(clonedContainer);

      const finalX = parseInt(targetPosition.left);
      const finalY = parseInt(targetPosition.top);

      console.log('finalX:', finalX, 'finalY:', finalY);

      animatePuzzlePiece(
        wrapper,
        {
          x: finalX,
          y: finalY,
          delay: index * 0.1, // Stagger the animations
          duration: 1.5,
        },
        index,
        filteredPieces.length
      );
    });
  };

  return {
    animatePuzzlePieces,
  };
}
